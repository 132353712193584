@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Bold.eot');
    src: url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Bold.woff') format('woff'),
        url('ProximaNova-Bold.ttf') format('truetype'),
        url('ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Regular.eot');
    src: url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Regular.woff') format('woff'),
        url('ProximaNova-Regular.ttf') format('truetype'),
        url('ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-BoldIt.eot');
    src: url('ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-BoldIt.woff') format('woff'),
        url('ProximaNova-BoldIt.ttf') format('truetype'),
        url('ProximaNova-BoldIt.svg#ProximaNova-BoldIt') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-LightIt.eot');
    src: url('ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-LightIt.woff') format('woff'),
        url('ProximaNova-LightIt.ttf') format('truetype'),
        url('ProximaNova-LightIt.svg#ProximaNova-LightIt') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Semibold.eot');
    src: url('ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Semibold.woff') format('woff'),
        url('ProximaNova-Semibold.ttf') format('truetype'),
        url('ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-SemiboldIt.eot');
    src: url('ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-SemiboldIt.woff') format('woff'),
        url('ProximaNova-SemiboldIt.ttf') format('truetype'),
        url('ProximaNova-SemiboldIt.svg#ProximaNova-SemiboldIt') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Light.eot');
    src: url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Light.woff') format('woff'),
        url('ProximaNova-Light.ttf') format('truetype'),
        url('ProximaNova-Light.svg#ProximaNova-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Black.eot');
    src: url('ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Black.woff') format('woff'),
        url('ProximaNova-Black.ttf') format('truetype'),
        url('ProximaNova-Black.svg#ProximaNova-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Extrabld.eot');
    src: url('ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Extrabld.woff') format('woff'),
        url('ProximaNova-Extrabld.ttf') format('truetype'),
        url('ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
    font-weight: 800;
    font-style: normal;
}
