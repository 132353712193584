.breadcrumbs {

}
ol.breadcrumb {
  list-style: none;
  margin: 0;
  list-style: none;
  font-size: 14px;
  margin-bottom: 16px;
}

.breadcrumb-item {
  display: inline;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '/';
  padding: 0;
  color: #eee;
}

.breadcrumb-item a {
  color: #337ab7;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}
