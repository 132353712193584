@import "./variable.scss";
@import "./fonts.scss";

$familys: (
  'Apercu': $Apercu,
  'Arial': $Arial,
  'BrandonGrotesque': $BrandonGrotesque,
  'CourierNew': $CourierNew,
  'Georgia': $Georgia,
  'Gotham': $Gotham,
  'GTWalsheim': $GTWalsheimPro,
  'LucidaSansUnicode': $LucidaSansUnicode,
  'OpenSans': $OpenSans,
  'PierSans': $PierSans,
  'PlayfairDisplay': $PlayfairDisplay,
  'Poppins': $Poppins,
  'ProximaNova': $ProximaNova,
  'Roboto': $Roboto,
  'Tahoma': $Tahoma,
  'TimesNewRoman': $TimesNewRoman,
  'TrebuchetMs': $TrebuchetMs,
  'Verdana': $Verdana
);

// .headingFont-Gotham {
//    .material-textfield-label {
//     line-height: 28px !important;
//   }
// }

// $step    : 0.1;
// $from    : ceil( 1 /$step);
// $through : ceil( 100 /$step);
// // $unit    : 'px';
//
// @for $i from $from through $through {
//   $i : $i * $step;
//   .my-class {
//     letter-spacing: #{$i}px;
//   }
// }

@for $i from 0 through 100 {
   .headingSpacing-#{$i} {
    .hero__section--title,
    .section__title,
    .material-textfield-label,
    .detail__headings .detail__headings--title,
    .broker__avatar--item .broker__avatar--info h4,
    .apartment__detail--heading,
    .scheduleform__wrapper .scheduleform__wrapper--title,
    .advanced-options-container .title,
    .featured__listings--heading .featured__listings--title,
    .card-preview .card-preview-info .card-preview-price,
    .testimonials__one .testimonials-card .testimonials-description,
    .testimonials__two .testimonials-card .testimonials-description,
    .homeform-card h4,
    .filters__container__body .title,
    .card .card-header .card-title,
    .head-section-header .head-section-title,
    .section__team__details .team__details--right .btn-active-list,
    .section__team__details .team__details--left .team__details--info h2,
    .section__about .team__name,
    .schools-filter-list .schools-info .title,
    .location__map--info .location__name,
    #map-canvas .card-map-view .card-map-info .card-map-price,
    #map-canvas .card-map-view .card-map-info .card-map-amenities,
    #map-canvas .card-map-view .card-map-info .card-map-address,
    .react-modal-header .react-modal-title,
    .blank__view .blank__view__inner .title,
    .footer__title,
    footer .footer__wrapper h4 {
      letter-spacing: #{$i}px !important;
    }
  }
  .buttonSpacing-#{$i} {
    button.btn,
    a.btn,
    .broker__avatar--item .broker__avatar--info .broker__info a,
    .schools-filter-nav > li,
    .detail__headings--discription .short-text .readMoreText {
      letter-spacing: #{$i}px !important;
    }
  }
  .bodySpacing-#{$i} {
    .form-control,
    .search-tags,
    .custom-checkbox,
    .featured__listings--heading .featured__listings--sutitle,
    .card-preview .card-preview-info .card-preview-amenities,
    .card-preview .card-preview-info .card-preview-address,
    .multiselect__checkboxes__field,
    .section__about .team__type,
    .section__about .team__info,
    .favorites__info--list,
    .search-wrapper-open-container .search-wrapper-options-menu,
    .testimonials__one .testimonials-card .testimonials-author,
    .testimonials__two .testimonials-card .testimonials-author,
    .card-preview .card-preview-thumbnail-slider .carousel-status,
    .carousel__slider .carousel-status,
    .homeform-card p,
    .invalid-feedback,
    table.custom-table,
    .search__results--filters .search__results--view-count,
    .detail__headings .detail__headings--price,
    .detail__headings .detail__headings--info,
    .apartment__detail--list > li,
    .amenities__list > li,
    .payment-calculation-list > li,
    .schools-filter-list,
    .schools-note,
    .section__subtitle,
    .broker__avatar--item .broker__avatar--info p,
    .section__team__details .team__details--left .team__details--info p,
    .broker__avatar--item .broker__avatar--info .broker__info span,
    .react-modal-header .react-modal-subtitle,
    .schools-filter-list .schools-info .subtitle,
    .create__link,
    .ant-pagination,
    .nearby__list,
    .detail__headings--discription,
    .detail__headings--discription pre,
    .blank__view .blank__view__inner .subtitle,
    .zoom-details,
    footer .copyright-text,
    .footer-link-horizontal,
    footer .footer__wrapper p,
    footer .footer__wrapper pre,
    footer .footer__details .footer__address,
    footer .footer__details .footer__info,
    footer .footer-address {
      letter-spacing: #{$i}px !important;
    }
  }
}

@each $name, $value in $familys {
  .bodyFont-#{$name} {
   font-family: $value !important;
  }
}

// @each $name, $value in $familys {
//   .headingFont-#{$name} {
//     .hero__section--title,
//     .section__title,
//     .material-textfield-label,
//     .detail__headings .detail__headings--title,
//     .broker__avatar--item .broker__avatar--info h4,
//     .apartment__detail--heading,
//     .scheduleform__wrapper .scheduleform__wrapper--title,
//     .advanced-options-container .title,
//     .featured__listings--heading .featured__listings--title,
//     .card-preview .card-preview-info .card-preview-price,
//     .testimonials__one .testimonials-card .testimonials-description,
//     .testimonials__two .testimonials-card .testimonials-description,
//     .homeform-card h4,
//     .filters__container__body .title,
//     .card .card-header .card-title,
//     .head-section-header .head-section-title,
//     .section__team__details .team__details--right .btn-active-list,
//     .section__team__details .team__details--left .team__details--info h2,
//     .section__about .team__name,
//     .schools-filter-list .schools-info .title,
//     .location__map--info .location__name,
//     #map-canvas .card-map-view .card-map-info .card-map-price,
//     #map-canvas .card-map-view .card-map-info .card-map-amenities,
//     #map-canvas .card-map-view .card-map-info .card-map-address,
//     .react-modal-header .react-modal-title,
//     .blank__view .blank__view__inner .title,
//     .footer__title,
//     footer .footer__wrapper h4 {
//       font-family: $value !important;
//     }
//   }
//   .buttonFont-#{$name} {
//     button.btn,
//     a.btn,
//     .broker__avatar--item .broker__avatar--info .broker__info a,
//     .schools-filter-nav > li,
//     .custom-select.btn-sort-dropdown,
//     .detail__headings--discription .short-text .readMoreText {
//       font-family: $value !important;
//     }
//   }
//   .bodyFont-#{$name} {
//     .form-control,
//     .search-tags,
//     .custom-checkbox,
//     .featured__listings--heading .featured__listings--sutitle,
//     .card-preview .card-preview-info .card-preview-amenities,
//     .card-preview .card-preview-info .card-preview-address,
//     .multiselect__checkboxes__field,
//     .section__about .team__type,
//     .section__about .team__info,
//     .favorites__info--list,
//     .search-wrapper-open-container .search-wrapper-options-menu,
//     .testimonials__one .testimonials-card .testimonials-author,
//     .testimonials__two .testimonials-card .testimonials-author,
//     .card-preview .card-preview-thumbnail-slider .carousel-status,
//     .carousel__slider .carousel-status,
//     .homeform-card p,
//     .invalid-feedback,
//     table.custom-table,
//     .search__results--filters .search__results--view-count,
//     .detail__headings .detail__headings--price,
//     .detail__headings .detail__headings--info,
//     .apartment__detail--list > li,
//     .amenities__list > li,
//     .payment-calculation-list > li,
//     .schools-filter-list,
//     .schools-note,
//     .section__subtitle,
//     .broker__avatar--item .broker__avatar--info p,
//     .section__team__details .team__details--left .team__details--info p,
//     .broker__avatar--item .broker__avatar--info .broker__info span,
//     .react-modal-header .react-modal-subtitle,
//     .schools-filter-list .schools-info .subtitle,
//     .create__link,
//     .ant-pagination,
//     .nearby__list,
//     .detail__headings--discription,
//     .detail__headings--discription pre,
//     .blank__view .blank__view__inner .subtitle,
//     .zoom-details,
//     footer .copyright-text,
//     .footer-link-horizontal,
//     footer .footer__wrapper p,
//     footer .footer__wrapper pre,
//     footer .footer__details .footer__address,
//     footer .footer__details .footer__info,
//     footer .footer-address {
//       font-family: $value !important;
//     }
//   }
// }

// $fontWeights: (
//   '100': 100, // Thin 100
//   '200': 200, // Extra-light 200
//   '300': 300, // Light 300
//   '400': 400, // Regular 400
//   '500': 500, // Medium 500
//   '600': 600, // Semi-bold 600
//   '700': 700, // Bold 700
//   '800': 800, // Extra-bold 800
//   '900': 900 // Black 900
// );
// @each  $name, $value in $fontWeights {
//   .headingFontWeight-#{$name} {
//     .hero__section--title,
//     .section__title,
//     .material-textfield-label,
//     .detail__headings .detail__headings--title,
//     .broker__avatar--item .broker__avatar--info h4,
//     .apartment__detail--heading,
//     .scheduleform__wrapper .scheduleform__wrapper--title,
//     .advanced-options-container .title,
//     .featured__listings--heading .featured__listings--title,
//     .card-preview .card-preview-info .card-preview-price,
//     .testimonials__one .testimonials-card .testimonials-description,
//     .testimonials__two .testimonials-card .testimonials-description,
//     .homeform-card h4,
//     .filters__container__body .title,
//     .card .card-header .card-title,
//     .head-section-header .head-section-title,
//     .section__team__details .team__details--right .btn-active-list,
//     .section__team__details .team__details--left .team__details--info h2,
//     .section__about .team__name,
//     .schools-filter-list .schools-info .title,
//     .location__map--info .location__name,
//     #map-canvas .card-map-view .card-map-info .card-map-price,
//     #map-canvas .card-map-view .card-map-info .card-map-amenities,
//     #map-canvas .card-map-view .card-map-info .card-map-address,
//     .react-modal-header .react-modal-title,
//     .blank__view .blank__view__inner .title,
//     .footer__title,
//     footer .footer__wrapper h4  {
//       font-weight: $value !important;
//     }
//   }
//   .buttonFontWeight-#{$name} {
//     button.btn,
//     a.btn,
//     .broker__avatar--item .broker__avatar--info .broker__info a,
//     .schools-filter-nav > li,
//     .detail__headings--discription .short-text .readMoreText {
//       font-weight: $value !important;
//     }
//   }
//   .bodyFontWeight-#{$name} {
//     .form-control,
//     .search-tags,
//     .custom-checkbox,
//     .featured__listings--heading .featured__listings--sutitle,
//     .card-preview .card-preview-info .card-preview-amenities,
//     .card-preview .card-preview-info .card-preview-address,
//     .multiselect__checkboxes__field,
//     .section__about .team__type,
//     .section__about .team__info,
//     .favorites__info--list,
//     .search-wrapper-open-container .search-wrapper-options-menu,
//     .testimonials__one .testimonials-card .testimonials-author,
//     .testimonials__two .testimonials-card .testimonials-author,
//     .card-preview .card-preview-thumbnail-slider .carousel-status,
//     .carousel__slider .carousel-status,
//     .homeform-card p,
//     .invalid-feedback,
//     table.custom-table,
//     .search__results--filters .search__results--view-count,
//     .detail__headings .detail__headings--price,
//     .detail__headings .detail__headings--info,
//     .apartment__detail--list > li,
//     .amenities__list > li,
//     .payment-calculation-list > li,
//     .schools-filter-list,
//     .schools-note,
//     .section__subtitle,
//     .broker__avatar--item .broker__avatar--info p,
//     .section__team__details .team__details--left .team__details--info p,
//     .broker__avatar--item .broker__avatar--info .broker__info span,
//     .react-modal-header .react-modal-subtitle,
//     .schools-filter-list .schools-info .subtitle,
//     .create__link,
//     .ant-pagination,
//     .nearby__list,
//     .detail__headings--discription,
//     .detail__headings--discription pre,
//     .blank__view .blank__view__inner .subtitle,
//     .zoom-details,
//     footer .copyright-text,
//     .footer-link-horizontal,
//     footer .footer__wrapper p,
//     footer .footer__wrapper pre,
//     footer .footer__details .footer__address,
//     footer .footer__details .footer__info,
//     footer .footer-address {
//       font-weight: $value !important;
//     }
//   }
// }
