@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Bold.eot');
    src: url('PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('PlayfairDisplay-Bold.woff') format('woff'),
        url('PlayfairDisplay-Bold.ttf') format('truetype'),
        url('PlayfairDisplay-Bold.svg#PlayfairDisplay-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Regular.eot');
    src: url('PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('PlayfairDisplay-Regular.woff') format('woff'),
        url('PlayfairDisplay-Regular.ttf') format('truetype'),
        url('PlayfairDisplay-Regular.svg#PlayfairDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Italic.eot');
    src: url('PlayfairDisplay-Italic.eot?#iefix') format('embedded-opentype'),
        url('PlayfairDisplay-Italic.woff') format('woff'),
        url('PlayfairDisplay-Italic.ttf') format('truetype'),
        url('PlayfairDisplay-Italic.svg#PlayfairDisplay-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Black.eot');
    src: url('PlayfairDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('PlayfairDisplay-Black.woff') format('woff'),
        url('PlayfairDisplay-Black.ttf') format('truetype'),
        url('PlayfairDisplay-Black.svg#PlayfairDisplay-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-BlackItalic.eot');
    src: url('PlayfairDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('PlayfairDisplay-BlackItalic.woff') format('woff'),
        url('PlayfairDisplay-BlackItalic.ttf') format('truetype'),
        url('PlayfairDisplay-BlackItalic.svg#PlayfairDisplay-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-BoldItalic.eot');
    src: url('PlayfairDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('PlayfairDisplay-BoldItalic.woff') format('woff'),
        url('PlayfairDisplay-BoldItalic.ttf') format('truetype'),
        url('PlayfairDisplay-BoldItalic.svg#PlayfairDisplay-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

