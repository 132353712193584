@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-ThinItalic.eot');
    src: url('BrandonGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-ThinItalic.woff') format('woff'),
        url('BrandonGrotesque-ThinItalic.ttf') format('truetype'),
        url('BrandonGrotesque-ThinItalic.svg#BrandonGrotesque-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Light.eot');
    src: url('BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Light.woff') format('woff'),
        url('BrandonGrotesque-Light.ttf') format('truetype'),
        url('BrandonGrotesque-Light.svg#BrandonGrotesque-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Regular.eot');
    src: url('BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Regular.woff') format('woff'),
        url('BrandonGrotesque-Regular.ttf') format('truetype'),
        url('BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Medium.eot');
    src: url('BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Medium.woff') format('woff'),
        url('BrandonGrotesque-Medium.ttf') format('truetype'),
        url('BrandonGrotesque-Medium.svg#BrandonGrotesque-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Thin.eot');
    src: url('BrandonGrotesque-Thin.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Thin.woff') format('woff'),
        url('BrandonGrotesque-Thin.ttf') format('truetype'),
        url('BrandonGrotesque-Thin.svg#BrandonGrotesque-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-MediumItalic.eot');
    src: url('BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-MediumItalic.woff') format('woff'),
        url('BrandonGrotesque-MediumItalic.ttf') format('truetype'),
        url('BrandonGrotesque-MediumItalic.svg#BrandonGrotesque-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Bold.eot');
    src: url('BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Bold.woff') format('woff'),
        url('BrandonGrotesque-Bold.ttf') format('truetype'),
        url('BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-LightItalic.eot');
    src: url('BrandonGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-LightItalic.woff') format('woff'),
        url('BrandonGrotesque-LightItalic.ttf') format('truetype'),
        url('BrandonGrotesque-LightItalic.svg#BrandonGrotesque-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Black.eot');
    src: url('BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Black.woff') format('woff'),
        url('BrandonGrotesque-Black.ttf') format('truetype'),
        url('BrandonGrotesque-Black.svg#BrandonGrotesque-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-BlackItalic.eot');
    src: url('BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-BlackItalic.woff') format('woff'),
        url('BrandonGrotesque-BlackItalic.ttf') format('truetype'),
        url('BrandonGrotesque-BlackItalic.svg#BrandonGrotesque-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-RegularItalic.eot');
    src: url('BrandonGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-RegularItalic.woff') format('woff'),
        url('BrandonGrotesque-RegularItalic.ttf') format('truetype'),
        url('BrandonGrotesque-RegularItalic.svg#BrandonGrotesque-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-BoldItalic.eot');
    src: url('BrandonGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-BoldItalic.woff') format('woff'),
        url('BrandonGrotesque-BoldItalic.ttf') format('truetype'),
        url('BrandonGrotesque-BoldItalic.svg#BrandonGrotesque-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

