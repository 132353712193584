@font-face {
    font-family: 'Apercu';
    src: url('Apercu-Bold.eot');
    src: url('Apercu-Bold.eot?#iefix') format('embedded-opentype'),
        url('Apercu-Bold.woff') format('woff'),
        url('Apercu-Bold.ttf') format('truetype'),
        url('Apercu-Bold.svg#Apercu-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-MediumItalic.eot');
    src: url('Apercu-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Apercu-MediumItalic.woff') format('woff'),
        url('Apercu-MediumItalic.ttf') format('truetype'),
        url('Apercu-MediumItalic.svg#Apercu-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-Regular.eot');
    src: url('Apercu-Regular.eot?#iefix') format('embedded-opentype'),
        url('Apercu-Regular.woff') format('woff'),
        url('Apercu-Regular.ttf') format('truetype'),
        url('Apercu-Regular.svg#Apercu-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-Light.eot');
    src: url('Apercu-Light.eot?#iefix') format('embedded-opentype'),
        url('Apercu-Light.woff') format('woff'),
        url('Apercu-Light.ttf') format('truetype'),
        url('Apercu-Light.svg#Apercu-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-BoldItalic.eot');
    src: url('Apercu-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Apercu-BoldItalic.woff') format('woff'),
        url('Apercu-BoldItalic.ttf') format('truetype'),
        url('Apercu-BoldItalic.svg#Apercu-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-Medium.eot');
    src: url('Apercu-Medium.eot?#iefix') format('embedded-opentype'),
        url('Apercu-Medium.woff') format('woff'),
        url('Apercu-Medium.ttf') format('truetype'),
        url('Apercu-Medium.svg#Apercu-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

