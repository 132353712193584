.aside {
  width: 100%;
  background-color: #fff;
  box-shadow: inset -1px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem 0;
}

.aside-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  .aside-list-item {
    position: relative;
    width: 100%;
    padding: .5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #666666;
    position: relative;
    &.active {
      &::before {
        content: '';
        height: 100%;
        width: 4px;
        display: block;
        background-color: #4c47cc;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0 4px 4px 0;
      }
      color: #4c47cc;
      // background-color: rgba(75, 71, 204, 0.1);
      text-decoration: none;
    }
    &:not(.active) {
      &:hover {
        color: #4c47cc;
        // background-color: #EBEBEB;
        background-color: rgba(75, 71, 204, 0.1);
      }
    }

  }
}
 
.aside-list-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  color: inherit;
  text-decoration: none;

  .aside-list-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 8px;
    border-radius: 4px;
     
    svg {
      width: 100%;
      height: 100%;
      stroke-width: 1.8px;
    }
  }
  
  .aside-list-text {
    // color: #fff;
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    text-align: left;

    span {
      display: inline-flex;
      text-align: center;
      padding: 3px;
      font-size: 12px;
      line-height: normal;
      border-radius: 6px;
      color: #fff;
      background-color: #fff;
    }
  }
}

.aside-nav-divider {
  margin: 1rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}