.grid-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.grid-list-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  &.active {
    box-shadow: 0px 0px 0px 2px rgb(76 71 204);
    .grid-card-overlay {
      opacity: 1;
      visibility: visible;
    }
  }
  &:hover {
    .grid-card-overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}
.grid-card-link {
  color: inherit;
  text-decoration: inherit;
}
.grid-card {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid-card-thumb {
  overflow: hidden;
  padding-top: 66.66%;
  position: relative;
  background-color: #f5f5f5;

}
.grid-card-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.grid-card-overlay {
  position: absolute;
  background: #000000bd;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}
.grid-card-info {
  padding: 16px;
  position: relative;
}

.grid-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.grid-card-domain {
  font-size: 14px;
  color: #666;
}

.grid-card-timestamp {
  font-size: 12px;
  color: #999;
  margin-top: auto;
}
