
.client-top-nav {
  -webkit-app-region: drag;
  grid-column: 1 / span 2;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
  position: relative;
  background: #fff;
  box-shadow: inset 0 -1px rgb(0 0 0 / 8%);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}
.client-top-nav-spacer {
  flex: none;
  width: 76px;
  height: 50px;
  margin-right: auto;
  -webkit-app-region: no-drag;
}

.client-top-nav-container-wrapper {
  display: flex;
  align-items: center;
  flex: auto;
}
.client-top-nav-left-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 16px;
}
.client-top-nav-brand {
  font-size: 16px;
  font-weight: 700;
}
.client-top-nav-middle-container {}
.client-top-nav-right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 auto;
  gap: 12px;
  min-width: 128px;
  padding-left: 32px;
  padding-right: 16px;
  position: relative;
}

.client-top-nav-anchor {}
.client-top-nav-user-button {
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-height: 30px;
  padding: 0 8px;
  color: #000;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  &:hover {
    background-color: #f5f5f5;
  }
}
.client-top-nav-user {}
.client-top-nav-user-avatar {}


.client-profile-wrapper {
  min-width: 200px;
  .client-profile-name {
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }
  .client-profile-role {
    font-size: 12px;
  }
  .client-profile-email {
    font-size: 12px;
  }
}