



/*================================================
1. Default CSS
2. Menu CSS
3. About CSS
4. Service CSS
5. Industries CSS
6. Achievement CSS
7. Data CSS
8. Blog CSS
9. FAQ CSS
10. Subscribe CSS
11. Footer CSS
12. Testimonial CSS
13. Hero CSS
14. Price CSS
15. Choose CSS
16. Team CSS
17. Error CSS
18. Works CSS
19. Showcase CSS
20. Features CSS
21. Creativity CSS
22. Form CSS
23. Terms and Condition CSS
24. Contact CSS

=================================================*/
/*================================================
1. Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");
/* Media Query Variable */
/*CSS Variable*/
:root {
  /*font Variable*/
  --ff-inter: 'Inter', sans-serif;
  --ff-inter-tight: 'Inter Tight', sans-serif;
  /*Color Variable*/
  --primary-black: #02021A;
  --primary-green: #1D9E43;
  --primary-purple: #6E45E9;
  --color-light-black: #0E0F23;
  --color-white: #FFF;
  --color-light-white: rgba(255, 255, 255, 0.70);
  --color-blue: #4229FF;
  --color-deep-blue: #00001D;
  --color-text-heading:#031811;
  --color-text-body:rgba(3, 24, 17, 0.70);
}
.template-site {
a {
  color: unset;
  text-decoration: none;
}

a:focus {
  outline: 0 solid;
}

a:hover {
  text-decoration: none;
  color: unset;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-family: var(--ff-inter);
  text-transform: capitalize;
  color: var(--color-text-heading);
}

h2.h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6;
  text-transform: capitalize;
}

@media only screen and (max-width: 1199.98px) {
  h2.h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 991.98px) {
  h2.h2 {
    font-size: 20px;
  }
}

h3.h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

@media only screen and (max-width: 991.98px) {
  h3.h3 {
    font-size: 18px;
  }
}

html, body {
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  font-family: var(--ff-inter-tight);
  color: var(--color-text-body);
}

p {
  margin-bottom: 0;
}

.text-p-18 {
  font-size: 18px;
}

@media only screen and (max-width: 991.98px) {
  .text-p-18 {
    font-size: 16px;
  }
}

input:focus, textarea:focus, button:focus {
  outline: none;
}

ul.custom-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

i.bx {
  vertical-align: middle;
}

/* Remove Chrome Input Field's Unwanted Yellow Background Color */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input:-webkit-autofill:hover, input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.bg_color_black {
  background-color: var(--primary-black);
}

.bg_color_black_two {
  background-color: #070B17;
}

.bg_color_black_three {
  background-color: #0A0E19;
}

.bg_color_light_black {
  background-color: var(--color-light-black);
}

.bg_color_light_black_two {
  background-color: #1B1D2B;
}

.bg_color_light_white {
  background-color: rgba(251, 251, 251, 0.8);
}

.bg_color_deep_blue {
  background-color: var(--color-deep-blue);
}

.bg_color_white {
  background-color: var(--color-white);
}

.text_color_white {
  color: var(--color-white);
}

.text_color_light_white {
  color: var(--color-light-white);
}

.mt-120 {
  margin-top: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-36 {
  margin-top: 36px;
}

.mb-96 {
  margin-bottom: 96px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-96 {
  padding-top: 96px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-96 {
  padding-bottom: 96px;
}

.z-index-one {
  z-index: 1 !important;
}

.z-index-minus-one {
  z-index: -1 !important;
}

.max-width-728 {
  max-width: 728px;
}

.max-width-500 {
  max-width: 500px;
}

.section-middle {
  margin: 0 auto;
}

@media only screen and (max-width: 991.98px) {
  .mt-120 {
    margin-top: 60px;
  }
  .mb-120 {
    margin-bottom: 60px;
  }
  .mt-60 {
    margin-top: 30px;
  }
  .mb-96 {
    margin-bottom: 36px;
  }
  .pt-120 {
    padding-top: 60px;
  }
  .pb-120 {
    padding-bottom: 60px;
  }
  .pt-60 {
    padding-top: 30px;
  }
  .pt-96 {
    padding-top: 36px;
  }
  .pb-60 {
    padding-bottom: 30px;
  }
  .pb-96 {
    padding-bottom: 36px;
  }
}

/*
============================================
============= Custom Scroll Bar CSS ================
*/
body::-webkit-scrollbar {
  width: .3em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

/*
============================================
============= Custom Scroll Bar CSS End ================
*/
/*
============================================
============= Preloader CSS ================
*/
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: var(--color-deep-blue);
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.preloader .spinner-wrap {
  width: 160px;
  height: 160px;
  position: relative;
}

.preloader .preloader-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.preloader .spinner {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #040404;
  border-top: 3px solid var(--primary-green);
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*
============= Preloader CSS END ================
================================================
*/
/* Custom Cursor */
.cursor {
  pointer-events: none;
  position: fixed;
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  mix-blend-mode: exclusion;
  -webkit-transition: opacity 0.4s ease, -webkit-transform .3s ease;
  transition: opacity 0.4s ease, -webkit-transform .3s ease;
  transition: transform .3s ease, opacity 0.4s ease;
  transition: transform .3s ease, opacity 0.4s ease, -webkit-transform .3s ease;
  z-index: 99999;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

.cursor-active {
  -webkit-transform: translate(-50%, -50%) scale(2);
          transform: translate(-50%, -50%) scale(2);
  opacity: .1;
  cursor: pointer;
}

/* Custom Cursor End */
/*Back to Top btn*/
.back-to-top-btn {
  position: fixed;
  bottom: 40px;
  right: 30px;
  background: #fff;
  mix-blend-mode: exclusion;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  display: none;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.back-to-top-btn i {
  color: #000;
  font-size: 18px;
  -webkit-transition: .3s;
  transition: .3s;
  -webkit-text-stroke: 1px;
}

.back-to-top-btn i::before {
  vertical-align: middle;
}

/*Back to Top btn End*/
.container.custom-container {
  margin-left: calc((100% - 1320px) / 2);
  width: auto;
  min-width: auto;
  max-width: inherit;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .container.custom-container {
    margin-left: calc((100% - 1143px) / 2);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .container.custom-container {
    margin-left: calc((100% - 970px) / 2);
  }
}

@media (min-width: 100px) {
  .container.custom-container {
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .container.custom-container {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .container.custom-container {
    margin-left: calc((100% - 970px) / 2);
    width: auto;
    min-width: auto;
    max-width: inherit;
  }
}

@media (min-width: 1200px) {
  .container.custom-container {
    margin-left: calc((100% - 1143px) / 2);
    width: auto;
    min-width: auto;
    max-width: inherit;
  }
}

@media (min-width: 1400px) {
  .container.custom-container {
    margin-left: calc((100% - 1320px) / 2);
    width: auto;
    min-width: auto;
    max-width: inherit;
  }
}

.section-title .sub-title {
  font-family: var(--ff-inter);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  margin-bottom: 16px;
  line-height: normal;
}

.section-title .title {
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: capitalize;
}

.section-title .title span {
  font-weight: 400;
}

.section-title .desc {
  font-size: 18px;
  line-height: 1.7;
  margin-top: 24px;
}

@media only screen and (max-width: 1199.98px) {
  .section-title .title {
    font-size: 40px;
  }
  .section-title .desc {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 991.98px) {
  .section-title .title {
    font-size: 35px;
  }
  .section-title .desc {
    margin-top: 12px;
  }
}

.section-title.gradient-sub-title .gradient-sub {
  font-family: var(--ff-inter);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border-radius: 50px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-17.5%, rgba(255, 255, 255, 0.3)), color-stop(118.75%, rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) -17.5%, rgba(255, 255, 255, 0) 118.75%);
  padding: 1px 16px;
  display: inline-block;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1199.98px) {
  .section-title.gradient-sub-title .gradient-sub {
    font-size: 16px;
  }
}

.section-title.gradient-sub-title .title {
  font-size: 56px;
}

@media only screen and (max-width: 1199.98px) {
  .section-title.gradient-sub-title .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 991.98px) {
  .section-title.gradient-sub-title .title {
    font-size: 35px;
  }
}

.section-title.gradient-sub-title.gradient-sub-border .gradient-sub-border-wrap {
  padding: 1.5px;
  margin-bottom: 16px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.section-title.gradient-sub-title.gradient-sub-border .gradient-sub-border-wrap::before {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  background: linear-gradient(80deg, rgba(66, 41, 255, 0.8) 17.87%, rgba(255, 255, 255, 0.5) 45.75%, rgba(66, 41, 255, 0.8) 80.38%);
  height: 410%;
  width: 101%;
  -webkit-animation: animate_border 4s linear infinite;
          animation: animate_border 4s linear infinite;
}

.section-title.gradient-sub-title.gradient-sub-border .gradient-sub {
  background: #1A1B3A;
  margin-bottom: 0;
  padding: 5px 24px;
  position: relative;
  z-index: 1;
}

.section-title.border-style .border-sbu {
  color: var(--color-text-heading);
  font-family: var(--ff-inter);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 991.98px) {
  .section-title.border-style .border-sbu {
    font-size: 18px;
  }
}

.section-title.border-style .border-sbu::before {
  position: absolute;
  background: -webkit-gradient(linear, left top, right top, from(#031811), to(rgba(3, 24, 17, 0)));
  background: linear-gradient(90deg, #031811 0%, rgba(3, 24, 17, 0) 100%);
  left: 0;
  bottom: 0;
  height: 1.5px;
  width: 100%;
  content: "";
}

.section-title.border-style .border-sbu.border-sbu-middle::before {
  background: -webkit-gradient(linear, left top, right top, from(rgba(3, 24, 17, 0)), color-stop(47.3%, #031811), color-stop(92.66%, rgba(3, 24, 17, 0)));
  background: linear-gradient(90deg, rgba(3, 24, 17, 0) 0%, #031811 47.3%, rgba(3, 24, 17, 0) 92.66%);
  left: 50%;
  bottom: 0;
  height: 1.3px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.section-title.border-style .title {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.3;
}

@media only screen and (max-width: 1199.98px) {
  .section-title.border-style .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 991.98px) {
  .section-title.border-style .title {
    font-size: 35px;
  }
}

.common-btn {
  font-weight: 500;
  letter-spacing: 0.32px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1;
  gap: 10px;
  font-size: 18px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 13px 22px;
  border-radius: 32px;
  color: var(--color-white);
  -webkit-transition: all .4s linear;
  transition: all .4s linear;
  overflow: hidden;
  border: 1px solid transparent;
}

.common-btn.border-style {
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.32);
  background: var(--color-light-black);
}

.common-btn.border-style:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(117, 172, 255, 0)), to(rgba(255, 255, 255, 0.12)));
  background: linear-gradient(rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
  opacity: 1;
  color: var(--color-white);
}

.common-btn.border-style.border-style-transparent {
  background: transparent;
  border: 1px solid #fff;
}

.common-btn.border-style.border-style-transparent:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(117, 172, 255, 0)), to(rgba(255, 255, 255, 0.12)));
  background: linear-gradient(rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
  opacity: 1;
  color: var(--color-white);
}

.common-btn.bg-white-style {
  color: var(--primary-black);
  background-color: var(--color-white);
}

.common-btn.bg-white-style:hover {
  background-color: #f2f2f2;
}

.common-btn.bg-green-style {
  color: var(--color-white);
  background-color: var(--primary-green);
}

.common-btn.bg-green-style:hover {
  background-color: #188137;
}

.common-btn.bg-blue-style {
  color: var(--color-white);
  background-color: var(--color-blue);
}

.common-btn.bg-blue-style:hover {
  background-color: #5842ff;
}

.common-btn.bg-purple-style {
  color: var(--color-white);
  background-color: var(--primary-purple);
}

.common-btn.bg-purple-style:hover {
  background-color: #825fec;
}

@media only screen and (max-width: 991.98px) {
  .common-btn {
    font-size: 16px;
  }
}

.breadcrumb-area {
  padding: 243px 0 156px;
  background-color: var(--color-deep-blue);
}

.breadcrumb-area::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/breadcrumb-img.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
}

@media only screen and (max-width: 991.98px) {
  .breadcrumb-area {
    padding: 160px 0 60px;
  }
}

.breadcrumb-area.breadcrumb-area-two {
  background-image: url(../images/breadcrumb-img-two.png);
  background-size: 100% 100%;
}

.breadcrumb-video-bg {
  -o-object-fit: cover;
     object-fit: cover;
}

.breadcrumb-info .breadcrumb-nav {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-17.5%, rgba(255, 255, 255, 0.4)), color-stop(118.75%, rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) -17.5%, rgba(255, 255, 255, 0) 118.75%);
  display: inline-block;
  padding: 8px 16px;
  margin-bottom: 12px;
  border-radius: 50px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

@media only screen and (max-width: 991.98px) {
  .breadcrumb-info .breadcrumb-nav {
    font-size: 14px;
  }
}

.breadcrumb-info h1 {
  font-size: clamp(40px, 4vw, 64px);
  font-weight: 600;
}

.breadcrumb-info .breadcrumb-desc {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

@media only screen and (max-width: 991.98px) {
  .breadcrumb-info .breadcrumb-desc {
    margin-top: 14px;
  }
}

.border-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/*================================================
2. Menu CSS
=================================================*/
.header-top {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-613.39%, rgba(255, 255, 255, 0.5)), color-stop(283.93%, rgba(255, 255, 255, 0.04)), color-stop(283.93%, rgba(255, 255, 255, 0.5)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) -613.39%, rgba(255, 255, 255, 0.04) 283.93%, rgba(255, 255, 255, 0.5) 283.93%);
  padding: 8px 0;
  position: relative;
  z-index: 9 !important;
}

.header-top-contact .header-top-address {
  font-size: 18px;
}

.header-top-contact .header-top-address:hover {
  color: var(--color-white);
}

.header-top-contact .social-list {
  gap: 10px;
}

.header-top-contact .header-top-item {
  position: relative;
}

.header-top-contact .header-top-item:not(:last-child) {
  padding-right: 24px;
  margin-right: 24px;
}

.header-top-contact .header-top-item:not(:last-child)::before {
  position: absolute;
  background: rgba(255, 255, 255, 0.25);
  width: 1px;
  height: 70%;
  content: "";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.header-top-text {
  gap: 5px;
}

@media only screen and (max-width: 991.98px) {
  .header-top-text {
    font-size: 12px;
  }
}

.menu-area {
  width: 100%;
  padding: 18px 0px;
  position: absolute;
  left: 0;
  z-index: 9;
}

.menu-area.menu-area-border::before {
  position: absolute;
  background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), color-stop(51.86%, rgba(255, 255, 255, 0.12)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.12) 51.86%, rgba(255, 255, 255, 0) 100%);
  height: 2px;
  width: 100%;
  content: "";
  left: 0;
  bottom: 0;
}

@-webkit-keyframes slide_down_site {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide_down_site {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.mainmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mainmenu ul ul li ul {
  left: -215px;
  top: 64px;
}

.mainmenu ul ul li:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 15px;
}

.mainmenu li {
  display: inline-block;
  position: relative;
  padding: 0 53px 0 0;
}

@media only screen and (max-width: 1199.98px) {
  .mainmenu li {
    padding: 0 32px 0 0;
  }
}

.mainmenu li a {
  padding: 9px 0;
  -webkit-transition: .3s;
  transition: .3s;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.mainmenu li ul {
  position: absolute;
  background: #f3f3f3;
  min-width: 200px;
  height: auto;
  -webkit-transform-origin: 0 0 0;
          transform-origin: 0 0 0;
  left: 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .5s;
  transition: all .5s;
  z-index: 9;
  padding: 10px 13px;
  border-radius: 16px;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.mainmenu li ul li {
  text-align: left;
  display: block;
  padding: 3px;
}

.mainmenu li ul li > a {
  color: var(--primary-black);
  font-weight: 500;
  -webkit-transition: .3s;
  transition: .3s;
}

.mainmenu li ul li > a:hover {
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
}

.mainmenu li ul a {
  color: #fff;
  width: 85%;
  text-align: left;
  margin-left: 0px;
  padding: 0px 0px;
}

.mainmenu li:hover ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.mainmenu li:hover ul ul {
  visibility: hidden;
  opacity: 0;
}

.mainmenu li > a {
  -webkit-transition: .3s;
  transition: .3s;
}

.mainmenu li.has-child-menu > a {
  position: relative;
}

.mainmenu li.has-child-menu > a::before {
  position: absolute;
  left: 106%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 11px;
  font-family: bootstrap-icons !important;
  content: "\f282";
  -webkit-transition: all .5s;
  transition: all .5s;
}

.mainmenu li.has-child-menu:hover a::before {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.mainmenu li.has-child-menu ul li.has-child-menu > a::before {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 10px;
  font-family: bootstrap-icons !important;
  -webkit-text-stroke: 1px;
  content: "\f285";
  -webkit-transition: all .5s;
  transition: all .5s;
  color: #000;
}

@media only screen and (min-width: 992px) {
  .has-child-menu-mega-menu {
    position: unset !important;
  }
  .has-child-menu-mega-menu ul {
    display: none;
    z-index: -1;
  }
  .has-child-menu-mega-menu .sub-nav {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    right: 0;
    top: 55px;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    z-index: 5;
    border-radius: 28px;
    padding: 20px 0;
    background-color: #F3F3F3;
    max-width: 98%;
    margin: 0 auto;
    -webkit-transform-origin: 0 0 0;
            transform-origin: 0 0 0;
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  .has-child-menu-mega-menu .sub-nav .nav-item .nav-item-img {
    border-radius: 16px;
    padding: 6px;
    background-color: #ddd;
  }
  .has-child-menu-mega-menu .sub-nav .nav-item .nav-item-img img {
    border-radius: 14px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  .has-child-menu-mega-menu .sub-nav .nav-item h6 {
    font-weight: 600;
    font-size: 18px;
  }
  .has-child-menu-mega-menu .sub-nav .nav-item a {
    padding: 0;
  }
  .has-child-menu-mega-menu:hover .sub-nav {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

@media only screen and (max-width: 991.98px) {
  .has-child-menu-mega-menu .sub-nav {
    display: none;
  }
  .main-menu-wrap li {
    margin: 0;
    padding: 0;
  }
  .main-menu-wrap li.has-child-menu .sub-menu .has-child-menu .sub-menu {
    position: relative;
    top: unset;
    left: unset;
  }
  .main-menu-wrap li.has-child-menu:hover > .sub-menu {
    -webkit-transform: unset;
            transform: unset;
    opacity: unset;
    visibility: unset;
  }
  .main-menu-wrap li.has-child-menu .sub-menu li a {
    padding: 1em 10%;
  }
  .main-menu-wrap li.has-child-menu .sub-menu {
    position: relative;
    top: unset;
    min-width: unset;
    list-style: none;
    -webkit-transform: unset;
            transform: unset;
    opacity: 1;
    visibility: visible;
    -webkit-transition: unset;
    transition: unset;
    background: unset;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    border-radius: unset;
    border: unset;
    padding: 0;
  }
  .main-menu-wrap li.has-child-menu::before {
    display: none;
  }
  .mean-container .mean-nav {
    position: relative;
    margin-top: 48px;
  }
  .mean-container .mean-bar {
    position: absolute;
    background: transparent;
    padding: 0px 0;
    min-height: 0px;
    z-index: 2;
    top: 0;
    right: 0;
  }
  .mean-container a.meanmenu-reveal {
    right: 12px !important;
    color: #fff;
    font-weight: 600;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    display: -ms-inline-grid;
    display: inline-grid;
    padding: 12px;
    top: 5px;
  }
  .mean-container a.meanmenu-reveal i {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    line-height: 1;
  }
  .mean-container a.meanmenu-reveal span {
    background: #fff;
    height: 2px;
    margin-top: 2px;
  }
  .mean-container a.meanmenu-reveal span:first-child {
    margin-top: 0px;
  }
  .mean-container .mean-nav ul li a.mean-expand {
    height: 33px;
    border-left: unset !important;
    border-bottom: unset !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 27px !important;
    font-weight: 200;
    background: none;
    width: 100%;
  }
  .mean-container .mean-nav ul li a.mean-expand:hover {
    background: none;
  }
  .main-menu-wrap li.has-child-menu .sub-menu li a {
    color: #ffffffeb;
    font-size: 14px;
  }
  .mean-container .mean-nav > ul {
    height: 47vh;
    overflow-y: auto;
  }
  /* Menu button */
  .meanmenu-reveal ~ .mean-nav .menu-btn-wrap {
    display: none;
  }
  .meanmenu-reveal.meanclose ~ .mean-nav .menu-btn-wrap {
    display: block;
  }
  /* Menu button End */
  /* menu End */
}

/*================================================
3. About CSS
=================================================*/
.about-wrap {
  border-radius: 48px;
  padding: 60px 80px;
  gap: 15px;
}

.about-wrap .section-title {
  max-width: 278px;
}

.about-wrap .section-title .sub-title, .about-wrap .section-title .title {
  color: #02021F;
}

.about-wrap .about-info {
  max-width: 576px;
}

.about-wrap .about-info p {
  color: rgba(2, 2, 31, 0.7);
}

.about-wrap .about-info .disc-2 {
  margin: 18px 0 32px;
}

@media only screen and (max-width: 1199.98px) {
  .about-wrap {
    padding: 40px;
  }
  .about-wrap .about-info .disc-2 {
    margin: 10px 0 12px;
  }
}

.border-btn {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
  letter-spacing: 0.36px;
  color: #02021F;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  border-bottom: 1px solid #02021F;
  padding-bottom: 3px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.border-btn:hover .icon {
  -webkit-animation: .3s moving_side alternate;
          animation: .3s moving_side alternate;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.border-btn.border-btn-white {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

@-webkit-keyframes moving_side {
  0% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes moving_side {
  0% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.commitment-brand-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 2.88px;
}

.commitment-brand-title.commitment-brand-title-two {
  position: relative;
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}

.commitment-brand-title.commitment-brand-title-two::after, .commitment-brand-title.commitment-brand-title-two::before {
  position: absolute;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.6)));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 100%);
  width: 186px;
  height: 1px;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: -274px;
  z-index: -1;
}

@media only screen and (max-width: 1199.98px) {
  .commitment-brand-title.commitment-brand-title-two::after, .commitment-brand-title.commitment-brand-title-two::before {
    display: none;
  }
}

.commitment-brand-title.commitment-brand-title-two::after {
  left: auto;
  right: -274px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.6)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
}

.about-ai-info .section-title .title {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5;
}

@media only screen and (max-width: 1199.98px) {
  .about-ai-info .section-title .title {
    font-size: 30px;
  }
}

.section-border {
  padding: 100px 0;
}

@media only screen and (max-width: 991.98px) {
  .section-border {
    padding: 45px 0;
  }
}

.values-wrapper {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 80px 48px;
}

@media only screen and (max-width: 991.98px) {
  .values-wrapper {
    padding: 60px 30px;
  }
}

.values-heading .section-title .title {
  font-size: 48px;
}

@media only screen and (max-width: 1199.98px) {
  .values-heading .section-title .title {
    font-size: 35px;
  }
}

.values-box-wrap {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  grid-gap: 28px;
  max-width: 650px;
  margin: 0 0 0 auto;
}

@media only screen and (max-width: 767.98px) {
  .values-box-wrap {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
}

.values-box-wrap .single-values-box {
  position: relative;
  padding-left: 28px;
  overflow: hidden;
}

.values-box-wrap .single-values-box::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: #262739;
  width: 2px;
  height: 100%;
}

.values-box-wrap .single-values-box::after {
  position: absolute;
  left: 0;
  content: "";
  background-image: url(../images/shapes/values-sp.png);
  width: 2px;
  height: 81px;
  -webkit-animation: slide_up 6s linear infinite;
          animation: slide_up 6s linear infinite;
}

.values-box-wrap .single-values-box .values-icon {
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.06);
  width: 40px;
  height: 40px;
}

.values-box-wrap .single-values-box .values-icon svg, .values-box-wrap .single-values-box .values-icon i {
  color: #fff;
  -webkit-transition: all .1s linear;
  transition: all .1s linear;
}

.values-box-wrap .single-values-box h2 {
  margin: 12px 0 4px;
}

.values-box-wrap .single-values-box:hover .values-icon svg, .values-box-wrap .single-values-box:hover .values-icon i {
  color: #f6ba50;
}

@-webkit-keyframes slide_up {
  from {
    bottom: -81px;
  }
  to {
    bottom: 100%;
  }
}

@keyframes slide_up {
  from {
    bottom: -81px;
  }
  to {
    bottom: 100%;
  }
}

/*================================================
4. Service CSS
=================================================*/
.service-box-wrap {
  padding: 1px;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.service-box-wrap::before {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  opacity: 1;
  background: linear-gradient(115deg, rgba(255, 255, 255, 0.25) 11.62%, rgba(255, 255, 255, 0) 50.7%, rgba(255, 255, 255, 0.25) 92.88%);
  -webkit-transition: all 1s linear;
  transition: all 1s linear;
  content: "";
  z-index: 1;
}

.service-box-wrap .service-box {
  padding: 32px 36px;
  background-color: #0C0D25;
  border-radius: 24px;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  height: 100%;
  position: relative;
  z-index: 1;
}

.service-box-wrap .service-box h3 {
  color: var(--color-white);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 24px;
  position: relative;
  z-index: 2;
}

.service-box-wrap .service-box p {
  color: var(--color-light-white);
  margin-top: 24px;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 991.98px) {
  .service-box-wrap .service-box {
    font-size: 18px;
  }
}

.service-box-wrap:hover {
  background: linear-gradient(115deg, rgba(255, 255, 255, 0) 11.62%, rgba(255, 255, 255, 0) 50.7%, rgba(255, 255, 255, 0) 92.88%);
}

.service-box-wrap:hover::before {
  -webkit-animation: animate_border 6.5s linear infinite;
          animation: animate_border 6.5s linear infinite;
  opacity: 1;
  left: 50%;
  top: 50%;
  height: 269%;
  width: 115%;
}

/*================================================
5. Industries CSS
=================================================*/
.industries-box {
  border-radius: 24px;
  padding: 36px 36px 56px;
  background: #0C0D25;
  height: 100%;
}

.industries-box::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/shapes/line.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
  z-index: -1;
}

.industries-box .industries-img {
  border-radius: 16px;
  overflow: hidden;
}

.industries-box .industries-img img {
  border-radius: 16px;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
}

.industries-box .industries-info {
  margin-top: 56px;
  padding: 0 20px;
}

.industries-box .industries-info span {
  font-family: var(--ff-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  display: inline-block;
}

.industries-box .industries-info h2 {
  color: var(--color-white);
  margin: 12px 0 20px;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
}

.industries-box .industries-info p {
  margin-bottom: 48px;
}

.industries-box:hover .industries-img img {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

@media only screen and (max-width: 991.98px) {
  .industries-box {
    padding: 20px;
  }
  .industries-box .industries-info {
    margin-top: 36px;
    padding: 0 10px;
  }
  .industries-box .industries-info h2 {
    margin: 5px 0 10px;
    font-size: 22px;
  }
  .industries-box .industries-info p {
    margin-bottom: 30px;
  }
}

.industries-box.industries-box-design-two {
  gap: 100px;
  padding: 50px;
}

.industries-box.industries-box-design-two .industries-info {
  padding: 0px;
}

@media only screen and (max-width: 1399.98px) {
  .industries-box.industries-box-design-two {
    gap: 40px;
  }
  .industries-box.industries-box-design-two .industries-img {
    max-width: 400px;
    width: 100%;
  }
}

@media only screen and (max-width: 1199.98px) {
  .industries-box.industries-box-design-two .industries-info h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 991.98px) {
  .industries-box.industries-box-design-two {
    padding: 20px;
  }
  .industries-box.industries-box-design-two .industries-img {
    max-width: unset;
    width: 100%;
  }
}

/*================================================
6. Achievement CSS
=================================================*/
.achievement-line-wrap .achievement-single-line-wrap {
  position: relative;
}

.achievement-line-wrap .achievement-single-line-wrap:not(:last-child)::before {
  position: absolute;
  background-image: url(../images/shapes/count.png);
  content: "";
  left: 100%;
  top: 50%;
  width: 1px;
  height: 88px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media only screen and (max-width: 991.98px) {
  .achievement-line-wrap .achievement-single-line-wrap:not(:last-child)::before {
    display: none;
  }
}

.achievement-line-wrap.achievement-line-wrap-white .achievement-single-line-wrap:not(:last-child)::before {
  background-image: url(../images/shapes/count-white.png);
  height: 100%;
}

.achievement-count-box .counter-item {
  font-size: 60px;
  font-weight: 600;
}

.achievement-count-box .counter-item em {
  font-style: normal;
  margin-left: -10px;
  font-size: 55px;
}

.achievement-count-box p {
  font-size: 22px;
  margin-top: 4px;
}

@media only screen and (max-width: 991.98px) {
  .achievement-count-box .counter-item {
    font-size: 50px;
  }
  .achievement-count-box .counter-item em {
    font-size: 45px;
  }
  .achievement-count-box p {
    font-size: 18px;
    margin-top: 0;
  }
}

.site-border {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}

/*================================================
7. Data CSS
=================================================*/
.data-info span {
  font-family: var(--ff-inter);
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.data-info h2 {
  color: #FFF;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  text-transform: capitalize;
  margin: 16px 0 24px;
}

.data-info p {
  margin-bottom: 32px;
  font-size: 18px;
}

@media only screen and (max-width: 1199.98px) {
  .data-info h2 {
    font-size: 40px;
    margin: 10px 0 16px;
  }
  .data-info p {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 991.98px) {
  .data-info h2 {
    font-size: 35px;
  }
}

.data-img-wrap {
  padding-left: 40px;
}

.data-img-wrap .data-img-2 {
  -webkit-animation: slide_1 3s ease-in-out alternate infinite;
          animation: slide_1 3s ease-in-out alternate infinite;
  top: 10%;
  left: -40px;
}

@media only screen and (max-width: 991.98px) {
  .data-img-wrap {
    padding-left: 0px;
  }
}

.data-sp {
  left: -76px;
  bottom: -215px;
}

.listing {
  gap: 20px;
}

.listing li {
  color: var(--color-light-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  gap: 18px;
}

.listing.listing-two {
  gap: 12px;
}

.listing.listing-two li {
  gap: 12px;
  font-size: 16px;
}

@-webkit-keyframes slide_1 {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes slide_1 {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

/*================================================
8. Blog CSS
=================================================*/
.blog-box {
  padding: 20px 20px 40px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  height: 100%;
  position: relative;
  }

.blog-box .blog-img {
  position: relative;
  padding-top: 100%;
  
}

.blog-box .blog-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  // max-height: 340px;
  
  border-radius: 12px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.blog-box .blog-info h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6;
  text-transform: capitalize;
  margin: 40px 0 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

@media only screen and (max-width: 991.98px) {
  .blog-box .blog-info h2 {
    font-size: 20px;
  }
}

.blog-box .blog-info p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  margin-bottom: 28px;
}

.blog-box:hover .blog-img img {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

@media only screen and (max-width: 991.98px) {
  .blog-box .blog-info h2 {
    margin: 25px 0 6px;
  }
  .blog-box .blog-info p {
    margin-bottom: 18px;
  }
}

.blog-box.blog-box-sidebar {
  padding: 30px;
}

.blog-box.blog-box-sidebar .blog-meta {
  margin-top: 20px;
}

.blog-box.blog-box-sidebar .blog-info h2 {
  font-size: 32px;
  font-weight: 500;
  margin: 24px 0 12px;
  line-height: 150%;
}

@media only screen and (max-width: 1199.98px) {
  .blog-box.blog-box-sidebar .blog-info h2 {
    font-size: 22px;
    margin: 14px 0 8px;
  }
}

.blog-box.blog-box-sidebar .blog-info p {
  font-size: 18px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 1199.98px) {
  .blog-box.blog-box-sidebar .blog-info p {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .blog-box.blog-box-sidebar {
    padding: 20px;
  }
}

.blog-meta {
  gap: 28px;
  margin-top: 20px;
}

.blog-meta li {
  color: var(--color-light-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

.blog-meta li svg, .blog-meta li i {
  color: var(--color-white);
}

@media only screen and (max-width: 767.98px) {
  .blog-meta {
    gap: 15px;
    row-gap: 0px;
  }
}

.slider-button-wrap-arrow {
  gap: 24px;
}

.slider-button-wrap-arrow .slide-btn {
  -webkit-transition: all .3s;
  transition: all .3s;
  color: rgba(255, 255, 255, 0.25);
}

.slider-button-wrap-arrow .slide-btn:hover {
  color: #fff;
}

@media only screen and (max-width: 991.98px) {
  .slider-button-wrap-arrow .slide-btn svg {
    width: 50px;
  }
}

@media only screen and (max-width: 991.98px) {
  .slider-button-wrap-arrow {
    gap: 14px;
  }
}

.blog-box-wrapper {
  max-width: 850px;
  width: 100%;
  gap: 30px;
}

.sidebar-wrap .single-sidebar {
  padding: 30px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar-wrap .single-sidebar:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199.98px) {
  .sidebar-wrap .single-sidebar {
    padding: 20px;
  }
}

.sidebar-search input {
  width: 100%;
  background: transparent;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
  padding: 7px 80px 7px 24px;
}

.sidebar-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-search input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-search button {
  background-color: var(--color-blue);
  right: 0;
  padding: 0px 18px;
  border-radius: 0px 8px 8px 0px;
  color: var(--color-white);
}

.sidebar-title {
  color: var(--color-white);
  border-bottom: 1px solid #191933;
  padding-bottom: 12px;
  margin-bottom: 28px;
}

@media only screen and (max-width: 1199.98px) {
  .sidebar-title {
    padding-bottom: 8px;
    margin-bottom: 18px;
  }
}

.sidebar-recent-post-wrap .sidebar-recent-post-box:not(:last-child) {
  margin-bottom: 20px;
}

.sidebar-recent-post-wrap .sidebar-recent-post-box .recent-post-title {
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all .2s;
  transition: all .2s;
}

.sidebar-recent-post-wrap .sidebar-recent-post-box .recent-post-meta {
  color: var(--color-light-white);
  margin-top: 4px;
}

.sidebar-recent-post-wrap .sidebar-recent-post-box:hover .recent-post-title {
  color: var(--color-white);
}

.sidebar-category {
  gap: 20px;
}

@media only screen and (max-width: 1199.98px) {
  .sidebar-category {
    gap: 16px;
  }
}

.sidebar-category li {
  color: var(--color-light-white);
  -webkit-transition: all .3s;
  transition: all .3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-transform: capitalize;
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-category li:hover {
  color: var(--color-white);
}

.sidebar-newsletter input {
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.02);
  color: var(--color-light-white);
  padding: 12px 24px;
  margin-bottom: 12px;
}

.sidebar-newsletter input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-newsletter input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-newsletter input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-newsletter input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--ff-inter);
  font-size: 18px;
}

.sidebar-newsletter .common-btn {
  padding: 16px 22px;
}

.page-list {
  gap: 16px;
}

.page-list li:not(:first-child):not(:last-child) {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid transparent;
  color: var(--color-blue);
  font-size: 14px;
  font-weight: 700;
}

.page-list li svg, .page-list li i {
  color: var(--color-light-white);
  -webkit-transition: all .2s;
  transition: all .2s;
}

.page-list li svg:hover, .page-list li i:hover {
  color: var(--color-white);
}

.page-list li.active:not(:first-child):not(:last-child) {
  color: var(--color-white);
  border: 1px solid var(--color-light-white);
}

.blog-details-info-wrap {
  max-width: 1110px;
  padding-left: 48px;
  width: 100%;
}

.blog-details-info-wrap .details-title-gap {
  margin-bottom: 32px;
}

@media only screen and (max-width: 991.98px) {
  .blog-details-info-wrap .details-title-gap {
    margin-bottom: 20px;
  }
}

.blog-details-info-wrap .details-section-gap {
  margin-bottom: 48px;
}

@media only screen and (max-width: 991.98px) {
  .blog-details-info-wrap .details-section-gap {
    margin-bottom: 30px;
  }
}

.blog-details-info-wrap .details-text-gap {
  margin-top: 24px;
}

@media only screen and (max-width: 991.98px) {
  .blog-details-info-wrap .details-text-gap {
    margin-top: 12px;
  }
}

.blog-details-info-wrap .blog-meta {
  gap: 32px;
  margin-bottom: 48px;
}

.blog-details-info-wrap .blog-meta li {
  font-size: 18px;
  gap: 20px;
}

@media only screen and (max-width: 991.98px) {
  .blog-details-info-wrap .blog-meta {
    gap: 15px;
    row-gap: 12px;
    margin-bottom: 30px;
  }
}

.blog-details-info-wrap .blog-details-title {
  font-size: clamp(34px, 4vw, 56px);
  font-weight: 600;
  line-height: 128.571%;
}

.blog-details-info-wrap .blog-details-sub-title {
  font-size: 32px;
  font-weight: 500;
  color: var(--color-white);
}

@media only screen and (max-width: 991.98px) {
  .blog-details-info-wrap .blog-details-sub-title {
    font-size: 22px;
  }
}

.blog-details-info-wrap p {
  font-size: 18px;
  line-height: 177.778%;
}

@media only screen and (max-width: 1199.98px) {
  .blog-details-info-wrap {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 991.98px) {
  .blog-details-info-wrap {
    padding-left: 0px;
  }
}

.wp-block-quote {
  border-radius: 10px;
  background: url(../images/blog/quote-box-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 48px;
  z-index: 1;
  margin: 64px 0;
}

@media only screen and (max-width: 991.98px) {
  .wp-block-quote {
    padding: 25px 25px 100px;
    margin: 34px 0;
  }
}

.wp-block-quote::after {
  position: absolute;
  background-image: url(../images/blog/quote.svg);
  background-repeat: no-repeat;
  content: "";
  right: 48px;
  bottom: 48px;
  width: 72px;
  height: 72px;
  z-index: -1;
}

@media only screen and (max-width: 991.98px) {
  .wp-block-quote::after {
    right: 18px;
    bottom: 18px;
  }
}

.wp-block-quote p {
  font-size: 28px;
  font-style: italic;
  font-weight: 500;
  line-height: 157.143%;
}

.wp-block-quote .quote-author {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

@media only screen and (max-width: 991.98px) {
  .wp-block-quote .quote-author {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.wp-block-quote .quote-author .quote-author-info .quote-author-title {
  color: #c6c5fa;
  font-size: 18px;
  line-height: 32px;
}

.wp-block-quote cite {
  font-family: var(--ff-inter);
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
}

.tag-list.blog-details-tag-list {
  gap: 20px;
}

.tag-list.blog-details-tag-list li {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  font-family: var(--ff-inter);
  font-style: italic;
  font-weight: 500;
  padding: 13px 28px;
  color: var(--color-white);
}

@media only screen and (max-width: 991.98px) {
  .tag-list.blog-details-tag-list li {
    padding: 8px 15px;
  }
}

@media only screen and (max-width: 991.98px) {
  .tag-list.blog-details-tag-list {
    gap: 10px;
  }
}

.blog-details-social {
  gap: 28px;
}

.blog-details-social .details-social-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 0;
}

.comments-wrap .single-comments-box {
  gap: 28px;
}

@media only screen and (max-width: 991.98px) {
  .comments-wrap .single-comments-box {
    gap: 12px;
  }
}

.comments-wrap .single-comments-box:not(:last-child) {
  margin-bottom: 30px;
}

.comments-wrap .single-comments-box:nth-child(even) {
  padding-left: 100px;
}

@media only screen and (max-width: 991.98px) {
  .comments-wrap .single-comments-box:nth-child(even) {
    padding-left: 20px;
  }
}

.comments-wrap .single-comments-box .comments-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding: 3px;
  overflow: hidden;
}

.comments-wrap .single-comments-box .comments-info h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 160%;
}

.comments-wrap .single-comments-box .comments-info p {
  font-size: 18px;
  margin: 4px 0 20px;
}

@media only screen and (max-width: 991.98px) {
  .comments-wrap .single-comments-box .comments-info p {
    margin: 4px 0 10px;
  }
}

.comments-wrap .single-comments-box .comments-info .reply-btn {
  gap: 8px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.comments-wrap .single-comments-box:hover .comments-info .reply-btn {
  color: #fff;
}

.comments-wrap .single-comments-box:hover .comments-info .reply-btn svg, .comments-wrap .single-comments-box:hover .comments-info .reply-btn i {
  color: var(--primary-green);
}

/*================================================
9. FAQ CSS
=================================================*/
.faq-wrap .accordion-item {
  background-color: transparent;
  border: unset;
}

.faq-wrap .accordion-item .accordion-collapse .accordion-body {
  padding: 0 0 24px;
  color: var(--color-light-white);
  padding-right: 90px;
}

.faq-wrap .accordion-item .accordion-collapse.show .accordion-body {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.faq-wrap .accordion-item:not(:last-child) {
  margin-bottom: 20px;
}

.faq-wrap .accordion-item:first-of-type, .faq-wrap .accordion-item:last-of-type {
  border-radius: unset;
}

.faq-wrap .accordion-item .accordion-button {
  font-family: var(--ff-inter);
  color: var(--color-white);
  padding: 0 0 23px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6;
  text-transform: capitalize;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap .accordion-item .accordion-button {
    font-size: 18px;
  }
}

.faq-wrap .accordion-item .accordion-button::after {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: bootstrap-icons !important;
  content: "\f4fe";
  background-image: unset;
  font-size: 25px;
  width: 35.833px;
  height: 35.833px;
  border: 2.5px solid rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  color: var(--color-light-white);
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap .accordion-item .accordion-button::after {
    font-size: 18px;
    width: 25px;
    height: 25px;
  }
}

.faq-wrap .accordion-item .accordion-button:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-color: rgba(255, 255, 255, 0.25);
}

.faq-wrap .accordion-item .accordion-button:not(.collapsed) {
  background-color: transparent;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-bottom: 1px solid transparent;
}

.faq-wrap .accordion-item .accordion-button:not(.collapsed)::after {
  font-family: bootstrap-icons !important;
  content: "\f2ea";
  border: 2.5px solid #fff;
  color: var(--color-white);
}

.faq-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.faq-wrap.faq-wrap-two .accordion-item {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 40px;
  -webkit-transition: all .5s linear;
  transition: all .5s linear;
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap.faq-wrap-two .accordion-item {
    padding: 20px;
  }
}

.faq-wrap.faq-wrap-two .accordion-item:hover {
  background-color: #060621;
}

.faq-wrap.faq-wrap-two .accordion-item .accordion-body {
  margin-top: 30px;
}

.faq-wrap.faq-wrap-two .accordion-item .accordion-collapse .accordion-body {
  padding: 0;
}

.faq-wrap.faq-wrap-two .accordion-item .accordion-collapse.show .accordion-body {
  border-bottom: unset;
}

.faq-wrap.faq-wrap-two .accordion-item:not(:last-child) {
  margin-bottom: 24px;
}

.faq-wrap.faq-wrap-two .accordion-item:first-of-type, .faq-wrap.faq-wrap-two .accordion-item:last-of-type {
  border-radius: 20px;
}

.faq-wrap.faq-wrap-two .accordion-item .accordion-button {
  padding: 0;
  font-size: 32px;
  font-weight: 500;
  border-bottom: unset;
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap.faq-wrap-two .accordion-item .accordion-button {
    font-size: 18px;
  }
}

.faq-wrap.faq-wrap-two .accordion-item .accordion-button::after {
  width: 35px;
  height: 35px;
  font-size: 35px;
  border: 2.5px solid transparent;
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap.faq-wrap-two .accordion-item .accordion-button::after {
    font-size: 22px;
  }
}

.faq-wrap.faq-wrap-two .accordion-item .accordion-button:focus {
  border-color: transparent;
}

.faq-wrap.faq-wrap-two .accordion-item .accordion-button:not(.collapsed)::after {
  border: 2.5px solid transparent;
}

.faq-wrap.faq-wrap-three .accordion-item:hover {
  background-color: #191F2F;
}

.faq-wrap.faq-wrap-white .accordion-item .accordion-body {
  color: var(--color-text-body);
  border-radius: 0px 0px 12px 12px;
  background: rgba(3, 24, 17, 0.02);
  font-size: 18px;
  padding: 40px;
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap.faq-wrap-white .accordion-item .accordion-body {
    padding: 20px;
  }
}

.faq-wrap.faq-wrap-white .accordion-item .accordion-button {
  color: var(--color-text-heading);
  padding: 0;
  font-size: 32px;
  font-weight: 500;
  border-bottom: unset;
  border-bottom: 1.5px solid rgba(3, 24, 17, 0.5);
  padding-bottom: 30px;
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap.faq-wrap-white .accordion-item .accordion-button {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap.faq-wrap-white .accordion-item .accordion-button {
    font-size: 18px;
  }
}

.faq-wrap.faq-wrap-white .accordion-item .accordion-button::after {
  color: var(--color-text-body);
  font-size: 34px;
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap.faq-wrap-white .accordion-item .accordion-button::after {
    font-size: 22px;
  }
}

.faq-wrap.faq-wrap-white .accordion-item .accordion-button:focus {
  border-color: rgba(3, 24, 17, 0.5);
}

.faq-wrap.faq-wrap-white .accordion-item .accordion-button:not(.collapsed)::after {
  border: 2.5px solid transparent;
}

.faq-wrap.faq-wrap-white .accordion-item:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991.98px) {
  .faq-wrap.faq-wrap-white .accordion-item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.faq-nav-list-wrap {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  margin: 0 0 0 auto;
}

.faq-nav-list-wrap .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 20px;
  background-color: transparent;
  min-width: 300px;
  width: 100%;
}

.faq-nav-list-wrap .nav-link .faq-list-title {
  font-family: var(--ff-inter);
  font-size: 24px;
  font-weight: 500;
  line-height: 166.667%;
  text-transform: capitalize;
  margin: 28px 0 4px;
}

.faq-nav-list-wrap .nav-link .faq-list-desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 177.778%;
}

.faq-nav-list-wrap .nav-link.active, .faq-nav-list-wrap .show > .nav-link {
  background-color: transparent;
  border: 1px solid var(--color-blue);
}

@media only screen and (max-width: 1399.98px) {
  .faq-nav-list-wrap .nav-link {
    min-width: 250px;
  }
  .faq-nav-list-wrap .nav-link svg {
    width: 50px;
    height: 50px;
  }
  .faq-nav-list-wrap .nav-link .faq-list-title {
    font-size: 22px;
    margin: 20px 0 4px;
  }
  .faq-nav-list-wrap .nav-link .faq-list-desc {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .faq-nav-list-wrap .nav-link {
    min-width: 200px;
  }
  .faq-nav-list-wrap .nav-link .faq-list-title {
    font-size: 20px;
    margin: 15px 0 4px;
  }
}

@media only screen and (max-width: 991.98px) {
  .faq-nav-list-wrap .nav-link {
    min-width: 150px;
  }
  .faq-nav-list-wrap .nav-link svg {
    width: 30px;
    height: 30px;
  }
  .faq-nav-list-wrap .nav-link .faq-list-title {
    font-size: 15px;
    margin: 12px 0 4px;
  }
  .faq-nav-list-wrap .nav-link .faq-list-desc {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767.98px) {
  .faq-nav-list-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 10px;
  }
  .faq-nav-list-wrap .nav-link {
    padding: 10px;
  }
}

/*================================================
10. Subscribe CSS
=================================================*/
.subscribe-wrap {
  padding: 100px 20px;
  border-radius: 32px;
  background: linear-gradient(212deg, #0023EC 13.33%, #9746D8 55.74%, #D100EB 93.86%);
}

.subscribe-wrap .section-title p {
  max-width: 806px;
  margin: 24px auto 0;
}

@media only screen and (max-width: 991.98px) {
  .subscribe-wrap {
    padding: 60px 20px;
  }
  .subscribe-wrap .section-title p {
    margin: 12px auto 0;
  }
}

.subscribe-wrap.subscribe-wrap-two {
  background-image: url(../images/subscribe-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.subscribe-wrap.subscribe-wrap-two .section-title .title {
  max-width: 740px;
  margin: 0 auto;
}

.subscribe-wrap.subscribe-wrap-two .section-title p {
  max-width: 462px;
  margin: 12px auto 32px;
}

.subscribe-wrap.subscribe-wrap-img-generator {
  background: -webkit-gradient(linear, left top, left bottom, from(#0E8F62), to(#1A503D));
  background: linear-gradient(180deg, #0E8F62 0%, #1A503D 100%);
}

.subscribe-wrap.subscribe-wrap-img-generator .section-title .title {
  max-width: 770px;
}

.subscribe-wrap.subscribe-wrap-img-generator .section-title p {
  max-width: 700px;
  margin: 24px auto 48px;
}

@media only screen and (max-width: 991.98px) {
  .subscribe-wrap.subscribe-wrap-img-generator .section-title p {
    margin: 14px auto 28px;
  }
}

.two-btn-wrap {
  gap: 28px;
}

.arrow-left-img {
  bottom: 70px;
}

@media only screen and (max-width: 1399.98px) {
  .arrow-left-img {
    width: 35%;
  }
}

@media only screen and (max-width: 991.98px) {
  .arrow-left-img {
    display: none;
  }
}

.arrow-right-img {
  bottom: 90px;
}

@media only screen and (max-width: 1399.98px) {
  .arrow-right-img {
    width: 35%;
  }
}

@media only screen and (max-width: 991.98px) {
  .arrow-right-img {
    display: none;
  }
}

.cs-subscribe-wrap {
  background: var(--primary-purple);
  border-radius: 60px;
}

@media only screen and (max-width: 991.98px) {
  .cs-subscribe-wrap {
    border-radius: 30px;
  }
}

.cs-subscribe-wrap .cs-subscribe-info {
  padding: 80px 0 80px 80px;
}

@media only screen and (max-width: 1199.98px) {
  .cs-subscribe-wrap .cs-subscribe-info {
    padding: 80px;
  }
}

@media only screen and (max-width: 991.98px) {
  .cs-subscribe-wrap .cs-subscribe-info {
    padding: 30px;
  }
}

.cs-subscribe-wrap .cs-subscribe-info h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 120.833%;
}

@media only screen and (max-width: 991.98px) {
  .cs-subscribe-wrap .cs-subscribe-info h2 {
    font-size: 35px;
  }
}

.cs-subscribe-wrap .cs-subscribe-info p {
  margin: 24px 0 48px;
}

@media only screen and (max-width: 991.98px) {
  .cs-subscribe-wrap .cs-subscribe-info p {
    margin: 14px 0 28px;
  }
}

.cs-subscribe-wrap .cs-subscribe-info .cs-sub-btn .common-btn {
  background-color: #0A0E19;
}

.cs-subscribe-wrap .cs-subscribe-info .cs-sub-btn .common-btn:hover {
  color: var(--color-white);
  background-color: #131a2f;
}

@media only screen and (max-width: 1399.98px) {
  .cs-subs-arrow {
    max-width: 40%;
  }
}

/*================================================
11. Footer CSS
=================================================*/
.footer-widget-wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding-bottom: 100px;
}

@media only screen and (max-width: 991.98px) {
  .footer-widget-wrapper {
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 991.98px) {
  .footer-widget {
    margin-bottom: 25px;
  }
}

.footer-widget-max {
  max-width: 340px;
}

.social-list {
  gap: 19px;
}

.social-list li {
  color: var(--color-white);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.social-list li:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.social-list.social-list-two {
  gap: 24px;
}

.social-list.social-list-two li a {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.social-list.social-list-two li a svg {
  width: 24px;
  height: 24px;
}

.footer-title {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  color: var(--color-white);
  margin-bottom: 32px;
}

@media only screen and (max-width: 991.98px) {
  .footer-title {
    margin-bottom: 22px;
  }
}

.footer-title.footer-title-two {
  position: relative;
  padding-left: 25px;
}

.footer-title.footer-title-two::before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-white);
  content: "";
}

.footer-link li {
  line-height: 1.7;
  font-size: 18px;
  color: var(--color-light-white);
  -webkit-transition: all .3s;
  transition: all .3s;
}
.footer-link li a {
  cursor: pointer;
}
.footer-link li:not(:last-child) {
  margin-bottom: 12px;
}

.footer-link li:hover {
  color: var(--color-white);
}

.footer-subscribe input {
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: transparent;
  padding: 10px 130px 10px 20px;
  color: #fff;
}

.footer-subscribe .common-btn {
  right: 4px;
  height: 87%;
}

.footer-subscribe.footer-subscribe-two input {
  border: unset;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 130px 10px 0px;
  border-radius: unset;
}

.footer-subscribe.footer-subscribe-two button {
  right: 0;
  height: 100%;
  color: var(--primary-green);
}

.footer-subscribe.footer-subscribe-two button:hover svg, .footer-subscribe.footer-subscribe-two button:hover i {
  -webkit-animation: .3s moving_side alternate;
          animation: .3s moving_side alternate;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.footer-subscribe.hero-subscribe {
  max-width: 639px;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#707C78), color-stop(54%, #2F403A), to(#707C78));
  background: linear-gradient(90deg, #707C78 0%, #2F403A 54%, #707C78 100%);
  margin: 40px auto 24px;
  padding: 1px;
  border-radius: 36px;
}

.footer-subscribe.hero-subscribe input {
  border-radius: 36px;
  border: unset;
  background: #2C3E38;
}

@media only screen and (max-width: 991.98px) {
  .footer-subscribe.hero-subscribe {
    margin: 20px auto 24px;
  }
}

.select1_inner {
  position: relative;
}

.select1_inner::after {
  pointer-events: none;
  content: "";
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 500px;
}

.select2-container--default .select2-selection--single {
  background: none;
  border-radius: 0;
  border: none;
  height: auto;
  width: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #f4f4f4;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #f4f4f4;
}

.selection {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.select2-dropdown {
  background: none;
  border-radius: 0;
  z-index: 100;
  min-width: 0px;
  border: none;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: 1px solid #ececec;
}

.select2-container--open .select2-dropdown--below {
  border-top: 1px solid #ececec;
}

.select2-results__option {
  padding: 10px 30px;
  font-size: 15px;
  line-height: 20px;
  background-color: #fff;
  color: #1b1b1b;
  border: 1px solid #ececec;
  text-align: center;
}

.select2-results__option:first-child {
  border-top: none;
}

.small .select2-results__option {
  padding-left: 0;
}

.copy-right-wrap {
  padding: 24px 0;
}

@media only screen and (max-width: 991.98px) {
  .copy-right-wrap {
    gap: 30px;
  }
}

.copy-right-wrap.copy-right-wrap-two {
  background: rgba(47, 35, 149, 0.15);
  -webkit-box-shadow: 0px -5px 150px 0px rgba(66, 41, 255, 0.3);
          box-shadow: 0px -5px 150px 0px rgba(66, 41, 255, 0.3);
}

.copy-right-wrap.copy-right-wrap-three {
  background: #041F16;
}

.copy-right-wrap-cs .footer-menu li {
  border-bottom: 1px solid;
  line-height: 1;
}

.copy-right-language {
  width: 115px;
}

.copy-right-language .select2 {
  width: 100% !important;
  color: var(--color-light-white);
  padding: 0 0px 0 30px;
}

.copy-right-language .language-icon {
  position: absolute;
  left: 0;
  margin-top: 3px;
}

.copy-right-language .language-icon-2 {
  position: absolute;
  right: 0;
}

.footer-menu {
  gap: 48px;
}

@media only screen and (max-width: 991.98px) {
  .footer-menu {
    gap: 15px;
  }
}

.footer-menu li {
  color: var(--color-light-white);
  -webkit-transition: all .3s;
  transition: all .3s;
}

.footer-menu li:hover {
  color: var(--color-white);
}

.footer-area-three {
  background-color: var(--color-text-heading);
}

.contact-page-footer {
  padding-top: 90px;
}

@media only screen and (max-width: 991.98px) {
  .contact-page-footer {
    padding-top: 60px;
  }
}

/*================================================
12. Testimonial CSS
=================================================*/
.testimonial-box {
  padding: 32px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #060621;
  height: 100%;
  max-width: 608px;
}

// @media only screen and (max-width: 991.98px) {
//   .testimonial-box {
//     max-width: 300px;
//   }
// }

.testimonial-box p {
  line-height: 1.7;
  margin: 24px 0;
}

.testimonial-box h3 {
  font-size: 20px;
  font-weight: 600;
}

.rating {
  gap: 8px;
}

.rating li {
  color: #f3b433;
}

.testimonial-slide-top .swiper-wrapper, .testimonial-slide-bottom .swiper-wrapper, .brand-slide-wrap .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
          transition-timing-function: linear !important;
  position: relative;
}

.testimonial-slide-top .swiper-slide, .testimonial-slide-bottom .swiper-slide, .brand-slide-wrap .swiper-slide {
  width: auto;
}

.brand-slide-wrap.side-shadow {
  position: relative;
}

.brand-slide-wrap.side-shadow::before, .brand-slide-wrap.side-shadow::after {
  background: -webkit-gradient(linear, right top, left top, color-stop(8.94%, rgba(7, 11, 23, 0)), color-stop(94.03%, #070B17));
  background: linear-gradient(270deg, rgba(7, 11, 23, 0) 8.94%, #070B17 94.03%);
  width: 187px;
  height: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  left: 0;
  position: absolute;
  z-index: 2;
}

.brand-slide-wrap.side-shadow::after {
  left: auto;
  right: 0;
  background: -webkit-gradient(linear, left top, right top, color-stop(8.94%, rgba(7, 11, 23, 0)), color-stop(94.03%, #070B17));
  background: linear-gradient(90deg, rgba(7, 11, 23, 0) 8.94%, #070B17 94.03%);
}

.testimonial-img-slide-wrap {
  border-radius: 32px;
  background: linear-gradient(187deg, #20352E -44.72%, rgba(32, 53, 46, 0) 94.23%);
  padding: 8px;
}

.testimonial-img-slide-wrap .testimonial-img-generator {
  border-radius: 26px;
  -webkit-transform: scale(0.8) rotate(10deg);
          transform: scale(0.8) rotate(10deg);
  -webkit-transition: all .4s linear;
  transition: all .4s linear;
}

.testimonial-info-wrap h2 {
  margin: 16px 0;
}

.testimonial-info-wrap h3 {
  font-size: 32px;
  font-weight: 500;
}

.testimonial-info-wrap p {
  font-size: 18px;
  margin-top: 4px;
  max-width: 300px;
}

@media only screen and (max-width: 1199.98px) {
  .testimonial-info-wrap p {
    max-width: 250px;
  }
}

@media only screen and (max-width: 991.98px) {
  .testimonial-info-wrap p {
    max-width: unset;
  }
}

.slider-button-wrap {
  gap: 24px;
}

.testimonial-info-slider-button-wrap {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 991.98px) {
  .testimonial-info-slider-button-wrap {
    position: relative;
    margin-top: 20px;
  }
}

.testimonial-img-slide .swiper-slide-active .testimonial-img-generator {
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
}

.cg-testimonial-area {
  background: -webkit-gradient(linear, left top, left bottom, from(#1B1D2B), to(#070B17));
  background: linear-gradient(180deg, #1B1D2B 0%, #070B17 100%);
}

.cg-testimonial-wrap {
  background: linear-gradient(284deg, rgba(255, 255, 255, 0) -39.66%, rgba(255, 255, 255, 0.15) 39.42%, rgba(255, 255, 255, 0) 112.84%);
  padding: 1.3px;
  border-radius: 20px;
  height: 100%;
}

.cg-testimonial-wrap .cg-testimonial-box {
  height: 100%;
  background-color: #181F2F;
  padding: 30px;
  border-radius: 20px;
}

.cg-testimonial-wrap .cg-testimonial-box .cg-testimonial-qt-icon {
  float: right;
  margin-top: -69px;
}

.cg-testimonial-wrap .cg-testimonial-box .cg-testimonial-commenter {
  gap: 12px;
}

.cg-testimonial-wrap .cg-testimonial-box .cg-testimonial-commenter img {
  max-width: 44px;
  width: 100%;
}

.cg-testimonial-wrap .cg-testimonial-box .cg-testimonial-commenter h3 {
  font-size: 20px;
  font-weight: 500;
}

.cg-testimonial-wrap .cg-testimonial-box .commenter-text {
  font-size: 22px;
  line-height: 1.72;
  margin: 20px 0;
}

.cg-testimonial-wrap .cg-testimonial-box .rating {
  font-size: 20px;
}

.cs-testimonial-info-wrap {
  border-radius: 24px;
  background: #191f2f;
  padding: 40px 40px 40px 76px;
}

@media only screen and (max-width: 767.98px) {
  .cs-testimonial-info-wrap {
    padding: 30px 30px 30px 38px;
  }
}

.cs-testimonial-info-wrap h2 {
  font-size: 32px;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
}

@media only screen and (max-width: 767.98px) {
  .cs-testimonial-info-wrap h2 {
    font-size: 25px;
  }
}

.cs-testimonial-info-wrap h2 .cs-testimonial-qt {
  left: -35px;
  top: 0;
}

@media only screen and (max-width: 767.98px) {
  .cs-testimonial-info-wrap h2 .cs-testimonial-qt {
    left: -25px;
    top: -7px;
  }
}

.cs-testimonial-info-wrap .cs-testimonial-desc {
  margin: 24px 0 32px;
}

@media only screen and (max-width: 767.98px) {
  .cs-testimonial-info-wrap .cs-testimonial-desc {
    margin: 14px 0 22px;
  }
}

.cs-testimonial-info-wrap .cs-testimonial-dev-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 767.98px) {
  .cs-testimonial-info-wrap .cs-testimonial-dev-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    gap: 10px;
  }
}

.cs-testimonial-info-wrap .cs-testimonial-dev-info .cs-testimonial-dev-name h3 {
  font-size: 20px;
  font-weight: 500;
}

.cs-testimonial-info-wrap .cs-testimonial-dev-info .cs-testimonial-dev-name p {
  margin-top: 8px;
}

.cs-testimonial-info-wrap .cs-testimonial-dev-info .cs-testimonial-rating .rating {
  gap: 8px;
}

.cs-testimonial-info-wrap .cs-testimonial-dev-info .cs-testimonial-rating .rating li {
  color: #f3b433;
}

.cs-testimonial-img {
  border-radius: 24px;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 400px;
}

/*================================================
13. Hero CSS
=================================================*/
.hero-area {
  padding: 264px 0 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 991.98px) {
  .hero-area {
    padding: 170px 0 60px;
  }
}

.hero-area-canvas {
  position: relative;
  overflow: hidden;
}

.hero-area-canvas canvas {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
}

.hero-area-img-generator {
  padding: 217px 0 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#163027), to(#0C1D17));
  background: linear-gradient(180deg, #163027 0%, #0C1D17 100%);
}

@media only screen and (max-width: 991.98px) {
  .hero-area-img-generator {
    padding: 150px 0 0;
  }
}

.hero-area-cg {
  background-color: #070B17;
  padding: 244px 0 0px;
}

@media only screen and (max-width: 991.98px) {
  .hero-area-cg {
    padding: 185px 0 0px;
  }
}

.hero-area-cs {
  padding: 218px 0 98px;
}

@media only screen and (max-width: 1199.98px) {
  .hero-area-cs {
    padding: 200px 0 60px;
  }
}

@media only screen and (max-width: 991.98px) {
  .hero-area-cs {
    padding: 130px 0 60px;
  }
  .hero-area-cs::before {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #00000063;
    content: "";
    top: 0;
    left: 0;
  }
}

.hero-cs-bg {
  max-width: 700px;
}

@media only screen and (max-width: 1199.98px) {
  .hero-cs-bg {
    max-width: 550px;
  }
}

@media only screen and (max-width: 767.98px) {
  .hero-cs-bg {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: left;
       object-position: left;
  }
}

.hero-gallery-wrap {
  max-width: 1680px;
  width: 100%;
  margin-top: 85px;
}

@media only screen and (max-width: 991.98px) {
  .hero-gallery-wrap {
    margin-top: 35px;
  }
}

.minus-margin {
  margin-top: -53px;
}

.hero-info .hero-sub-spn {
  border-radius: 50px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-17.5%, rgba(255, 255, 255, 0.3)), color-stop(118.75%, rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) -17.5%, rgba(255, 255, 255, 0) 118.75%);
  font-family: var(--ff-inter);
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.9px;
  padding: 8px 16px;
}

@media only screen and (max-width: 991.98px) {
  .hero-info .hero-sub-spn {
    font-size: 13px;
  }
}

.hero-info h1 {
  font-size: clamp(34px, 4vw, 64px);
  font-weight: 600;
  line-height: 1.25;
  margin: 20px 0 24px;
}

@media only screen and (max-width: 991.98px) {
  .hero-info h1 {
    margin: 15px 0 10px;
  }
}

.hero-info .hero-text {
  font-size: 18px;
}

.hero-info.hero-info-img-generator h1 {
  font-size: clamp(34px, 4vw, 56px);
  font-weight: 500;
  line-height: 132.143%;
}

.hero-info.hero-info-cs .hero-info-cs-sub {
  font-size: 18px;
  font-weight: 500;
}

.hero-info.hero-info-cs h1 {
  font-size: clamp(34px, 4vw, 60px);
  font-weight: 500;
  line-height: 1.2;
  margin: 12px 0 0;
}

.hero-info.hero-info-cs .border-divider {
  max-width: 555px;
  margin: 34px 0 19px;
}

.hero-info.hero-info-cs .hero-text {
  max-width: 544px;
}

.hero-info.hero-info-cs .two-btn-wrap {
  margin-top: 34px;
}

.hero-info.hero-info-cs .hero-arrow-sp {
  right: 50px;
  bottom: 5px;
}

@media only screen and (max-width: 1399.98px) {
  .hero-info.hero-info-cs .hero-arrow-sp {
    right: 25px;
  }
}

.cg-hero-info .section-title.gradient-sub-title .title {
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.32);
  font-size: clamp(34px, 4vw, 60px);
  font-weight: 500;
  line-height: 126.667%;
  text-transform: capitalize;
}

.cg-hero-info .section-title.gradient-sub-title.gradient-sub-border .gradient-sub-border-wrap {
  background: linear-gradient(20deg, rgba(66, 41, 255, 0.8) 17.87%, rgba(255, 255, 255, 0.5) 45.75%, rgba(66, 41, 255, 0.8) 80.38%);
  padding: 1px;
}

.cg-hero-info .section-title.gradient-sub-title.gradient-sub-border .gradient-sub-border-wrap::before {
  display: none;
}

@media only screen and (max-width: 991.98px) {
  .cg-hero-info .section-title.gradient-sub-title.gradient-sub-border .gradient-sub {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767.98px) {
  .hero-img-wrap {
    max-width: 300px;
  }
}

.cg-hero-img-wrap {
  margin-top: 100px;
  padding: 1.3px;
  padding-bottom: 0;
  display: inline-block;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}

@media only screen and (max-width: 991.98px) {
  .cg-hero-img-wrap {
    margin-top: 50px;
  }
}

.cg-hero-img-wrap::before {
  background: linear-gradient(151deg, rgba(66, 41, 255, 0.8) 17.16%, rgba(255, 255, 255, 0.8) 35.34%, rgba(66, 41, 255, 0.8) 57.49%);
  height: 276%;
  width: 166%;
  left: 50%;
  top: 50%;
  content: "";
  position: absolute;
  -webkit-animation: animate_border 8.5s linear infinite;
          animation: animate_border 8.5s linear infinite;
  z-index: 0;
}

.cg-hero-img-wrap .cg-hero-img-box {
  background-color: #1A1B3A;
  padding: 20px;
  border-radius: 20px 20px 0 0;
}

.brand-box svg {
  color: #a4a7ba;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.brand-box svg:hover {
  color: var(--color-white);
}

.tag-wrap {
  gap: 12px;
}

.tag-wrap .tag-title {
  font-size: 18px;
}

.tag-list {
  gap: 12px;
}

.tag-list li {
  color: var(--color-light-white);
  background-color: #2C3E38;
  border-radius: 40px;
  padding: 1px 15px;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.tag-list li:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  background-color: #3b544b;
}

.tag-list.sidebar-tag-list li {
  border-radius: 12px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  font-family: var(--ff-inter);
  padding: 3px 15px;
}

.tag-list.sidebar-tag-list li:hover {
  color: var(--color-white);
}

/* Bubble */
.bubbles-bg {
  height: 100%;
  overflow: hidden;
}

.bubbles-bg .bubbles span {
  position: relative;
  min-width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin: 0 4px;
  -webkit-animation: animate_dust 200s linear infinite;
          animation: animate_dust 200s linear infinite;
  -webkit-animation-duration: calc(175s / var(--i));
          animation-duration: calc(175s / var(--i));
}

.bubbles-bg .bubbles span:nth-child(even) {
  background-color: rgba(110, 69, 233, 0.5);
  border-radius: 0;
}

@-webkit-keyframes animate_dust {
  0% {
    -webkit-transform: translateY(100vh) scale(0.1);
            transform: translateY(100vh) scale(0.1);
  }
  100% {
    -webkit-transform: translateY(0) scale(0.19);
            transform: translateY(0) scale(0.19);
  }
}

@keyframes animate_dust {
  0% {
    -webkit-transform: translateY(100vh) scale(0.1);
            transform: translateY(100vh) scale(0.1);
  }
  100% {
    -webkit-transform: translateY(0) scale(0.19);
            transform: translateY(0) scale(0.19);
  }
}

/* Bubble End */
.bottom-img-generator-hero {
  background: #163027;
  padding: 60px 0;
}

.bottom-img-generator-hero .brand-box svg {
  color: #878787;
}

.bottom-img-generator-hero .brand-box svg:hover {
  color: #b3b3b3;
}

.bottom-img-generator-hero .brand-slide-wrap.side-shadow::before {
  background: -webkit-gradient(linear, right top, left top, color-stop(8.94%, rgba(7, 11, 23, 0)), color-stop(94.03%, #163027));
  background: linear-gradient(270deg, rgba(7, 11, 23, 0) 8.94%, #163027 94.03%);
}

.bottom-img-generator-hero .brand-slide-wrap.side-shadow::after {
  background: -webkit-gradient(linear, left top, right top, color-stop(8.94%, rgba(7, 11, 23, 0)), color-stop(94.03%, #163027));
  background: linear-gradient(90deg, rgba(7, 11, 23, 0) 8.94%, #163027 94.03%);
}

.bottom-img-generator-hero.bottom-img-generator-hero-cs {
  background: #0E1422;
}

/*================================================
14. Price CSS
=================================================*/
.switch-wrapper {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px;
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}

.switch-wrapper [type="radio"] {
  position: absolute;
  left: -9999px;
}

.switch-wrapper [type="radio"]:checked#monthly + label[for="yearly"] ~ .highlighter {
  -webkit-transform: none;
          transform: none;
}

.switch-wrapper [type="radio"]:checked#yearly + label[for="monthly"] ~ .highlighter {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.switch-wrapper label {
  font-family: var(--ff-inter);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
  min-width: 125px;
  cursor: pointer;
  border-radius: 30px;
  -webkit-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
  color: var(--color-light-white);
}

.switch-wrapper .highlighter {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(50% - 4px);
  height: calc(100% - 8px);
  border-radius: 30px;
  background: var(--color-blue);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.switch-wrapper.switch-wrapper-two {
  border: 1px solid rgba(3, 24, 17, 0.32);
}

.switch-wrapper.switch-wrapper-two label {
  color: var(--color-text-heading);
  border: 1px solid transparent;
}

.switch-wrapper.switch-wrapper-two .highlighter {
  border: 1px solid var(--primary-green);
  background: rgba(29, 158, 67, 0.1);
}

.switch-wrapper.switch-wrapper-purple .highlighter {
  background: var(--primary-purple);
}

.switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"],
.switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"] {
  color: var(--color-white);
}

.switch-wrapper.switch-wrapper-two [type="radio"]:checked#monthly ~ label[for="monthly"],
.switch-wrapper.switch-wrapper-two [type="radio"]:checked#yearly ~ label[for="yearly"] {
  color: var(--primary-green);
}

.switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"]:hover,
.switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"]:hover {
  background: transparent;
}

.price-box {
  border-radius: 24px;
  background: #0C0D25;
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.price-box .hide {
  display: none;
}

.price-box .price-value-box {
  border-radius: 24px;
  background: rgba(66, 41, 255, 0.07);
  padding: 48px 20px;
}

.price-box .price-value-box .price-plan {
  font-family: var(--ff-inter);
  font-size: 14px;
  letter-spacing: 0.7px;
  border-radius: 60px;
  border: 1px solid rgba(66, 41, 255, 0.2);
  background: rgba(66, 41, 255, 0.1);
  padding: 20px 23px;
  line-height: 0;
  -webkit-transition: all .4s linear;
  transition: all .4s linear;
}

.price-box .price-value-box h2 {
  font-size: 48px;
  margin: 28px 0 2px;
  -webkit-animation: priceFade .7s alternate linear forwards;
          animation: priceFade .7s alternate linear forwards;
}

@media only screen and (max-width: 991.98px) {
  .price-box .price-value-box h2 {
    font-size: 40px;
  }
}

.price-box .price-value-box h2 span {
  font-family: var(--ff-inter-tight);
  font-size: 18px;
  font-weight: 400;
}

.price-box .price-info-details li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  margin: 24px 0;
  font-size: 18px;
  padding: 0 32px 24px;
  color: var(--color-light-white);
}

.price-box .price-info-details li svg {
  color: rgba(66, 41, 255, 0.7);
  -webkit-transition: all .3s;
  transition: all .3s;
}

.price-box .price-info-details li:hover svg {
  color: #4229ff;
}

.price-box .price-info-btn-wrap {
  padding: 8px 32px 32px;
}

.price-box .price-info-btn-wrap .price-info-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  border-radius: 60px;
  border: 1px solid var(--color-blue);
  display: block;
  text-align: center;
  padding: 20px 10px;
  -webkit-transition: all .4s linear;
  transition: all .4s linear;
}

.price-box:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.price-box:hover .price-value-box .price-plan {
  -webkit-transform: rotateX(360deg);
          transform: rotateX(360deg);
}

.price-box:hover .price-info-btn-wrap .price-info-btn {
  border: 1px solid var(--color-blue);
  color: #fff;
  background-color: var(--color-blue);
}

.price-box.price-box-white {
  border-radius: 30px;
  background: #FCFCFC;
}

.price-box.price-box-white .price-value-box {
  border-radius: 30px;
  background: #EAEDFA;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(75, 98, 230, 0.5);
          box-shadow: 0px 1px 0px 0px rgba(75, 98, 230, 0.5);
  padding: 61px 20px 30px;
}

.price-box.price-box-white .price-value-box h2 {
  font-weight: 600;
  font-size: 64px;
}

@media only screen and (max-width: 1199.98px) {
  .price-box.price-box-white .price-value-box h2 {
    font-size: 45px;
  }
}

.price-box.price-box-white .price-value-box h2 span {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  font-size: 20px;
}

.price-box.price-box-white .price-value-box p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
}

@media only screen and (max-width: 1199.98px) {
  .price-box.price-box-white .price-value-box p {
    font-size: 18px;
  }
}

.price-box.price-box-white .price-value-box .price-plan {
  border-radius: 0px 0px 50px 50px;
  background: -webkit-gradient(linear, left bottom, left top, from(#FFF), to(#FFF)), #4B62E6;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #4B62E6;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 4px;
  color: var(--color-blue);
  top: -1px;
}

.price-box.price-box-white .price-info-details {
  margin: 50px 0;
}

@media only screen and (max-width: 1199.98px) {
  .price-box.price-box-white .price-info-details {
    margin: 30px 0;
  }
}

.price-box.price-box-white .price-info-details li {
  color: var(--color-text-body);
  position: relative;
  border-bottom: unset;
}

.price-box.price-box-white .price-info-details li svg {
  color: var(--primary-green);
}

.price-box.price-box-white .price-info-details li::before {
  position: absolute;
  background: -webkit-gradient(linear, left top, right top, from(rgba(3, 24, 17, 0)), color-stop(47.3%, rgba(3, 24, 17, 0.5)), color-stop(92.66%, rgba(3, 24, 17, 0)));
  background: linear-gradient(90deg, rgba(3, 24, 17, 0) 0%, rgba(3, 24, 17, 0.5) 47.3%, rgba(3, 24, 17, 0) 92.66%);
  height: 1px;
  width: 100%;
  content: "";
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (max-width: 1199.98px) {
  .price-box.price-box-white .price-info-details li {
    font-size: 16px;
  }
}

.price-box.price-box-white .price-info-btn-wrap {
  padding: 0px 32px 40px;
}

.price-box.price-box-white .price-info-btn-wrap .price-info-btn {
  color: var(--primary-green);
  border: 1px solid var(--primary-green);
}

.price-box.price-box-white:hover {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.price-box.price-box-white:hover .price-value-box .price-plan {
  -webkit-transform: unset;
          transform: unset;
}

.price-box.price-box-white:hover .price-info-btn-wrap .price-info-btn {
  border: 1px solid var(--primary-green);
  color: #fff;
  background-color: var(--primary-green);
}

.price-box.price-box-two {
  background: #11192C;
}

.price-box.price-box-two .price-value-box {
  background: rgba(110, 69, 233, 0.07);
}

.price-box.price-box-two .price-value-box .price-plan {
  background: rgba(110, 69, 233, 0.1);
  border: 1px solid rgba(110, 69, 233, 0.2);
}

.price-box.price-box-two .price-info-details li svg {
  color: rgba(110, 69, 233, 0.7);
}

.price-box.price-box-two .price-info-btn-wrap .price-info-btn {
  border: 1px solid var(--primary-purple);
}

.price-box.price-box-two:hover .price-info-btn-wrap .price-info-btn {
  border: 1px solid var(--primary-purple);
  color: #fff;
  background-color: var(--primary-purple);
}

.price-box-wrap-white {
  border-radius: 30px;
  background: -webkit-gradient(linear, left top, left bottom, from(#FCFCFC), to(#BEC3C1));
  background: linear-gradient(180deg, #FCFCFC 0%, #BEC3C1 100%);
  padding: 1px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.price-box-wrap-white:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.price_wrapper .single-price-box:nth-child(2) .price-box.price-box-white .price-value-box {
  background: rgba(250, 127, 39, 0.1);
  -webkit-box-shadow: 0px 1px 0px 0px rgba(250, 127, 39, 0.5);
          box-shadow: 0px 1px 0px 0px rgba(250, 127, 39, 0.5);
}

.price_wrapper .single-price-box:nth-child(2) .price-box.price-box-white .price-value-box .price-plan {
  background: #FA7F27;
  color: #fff;
}

.price_wrapper .single-price-box:nth-child(3) .price-box.price-box-white .price-value-box {
  background: rgba(247, 134, 190, 0.1);
  -webkit-box-shadow: 0px 1px 0px 0px rgba(247, 134, 190, 0.5);
          box-shadow: 0px 1px 0px 0px rgba(247, 134, 190, 0.5);
}

.price_wrapper .single-price-box:nth-child(3) .price-box.price-box-white .price-value-box .price-plan {
  color: #F786BE;
}

.cg-price-wrap {
  background: linear-gradient(206deg, rgba(255, 255, 255, 0) -20.59%, rgba(255, 255, 255, 0.1) 48.36%, rgba(255, 255, 255, 0) 112.38%);
  padding: 1.3px;
  -webkit-transition: all .4s;
  transition: all .4s;
  border-radius: 20px;
  height: 100%;
}

.cg-price-wrap .hide {
  display: none;
}

.cg-price-wrap .cg-price-box {
  border-radius: 20px;
  background-color: #1A1E2D;
  padding: 40px;
  height: 100%;
}

@media only screen and (max-width: 991.98px) {
  .cg-price-wrap .cg-price-box {
    padding: 20px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .cg-price-wrap .cg-price-box {
    padding: 30px;
  }
}

.cg-price-wrap .cg-price-box::before {
  position: absolute;
  background: linear-gradient(201deg, rgba(66, 41, 255, 0) 37.65%, #4229FF 151.6%);
  height: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  content: "";
  z-index: -1;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.cg-price-wrap .cg-price-box .cg-price-value-box .cg-price-plan {
  font-size: 20px;
  font-weight: 500;
}

.cg-price-wrap .cg-price-box .cg-price-value-box .cg-price-plan svg, .cg-price-wrap .cg-price-box .cg-price-value-box .cg-price-plan i {
  margin-left: 8px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.cg-price-wrap .cg-price-box .cg-price-value-box .price {
  font-size: 48px;
  font-weight: 500;
  margin: 48px 0 12px;
}

.cg-price-wrap .cg-price-box .cg-price-value-box .price span {
  font-family: var(--ff-inter-tight);
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 991.98px) {
  .cg-price-wrap .cg-price-box .cg-price-value-box .price {
    margin: 30px 0 12px;
  }
}

.cg-price-wrap .cg-price-box .cg-price-info-details {
  margin: 32px 0;
}

@media only screen and (max-width: 991.98px) {
  .cg-price-wrap .cg-price-box .cg-price-info-details {
    margin: 22px 0;
  }
}

.cg-price-wrap .cg-price-box .cg-price-info-details li {
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  color: #fff;
}

.cg-price-wrap .cg-price-box .cg-price-info-details li:not(:last-child) {
  margin-bottom: 12px;
}

.cg-price-wrap .cg-price-box .cg-price-info-details li svg, .cg-price-wrap .cg-price-box .cg-price-info-details li i {
  color: var(--color-blue);
}

.cg-price-wrap .cg-price-box .cg-price-info-details li svg.check-discolor, .cg-price-wrap .cg-price-box .cg-price-info-details li i.check-discolor {
  color: #25314C;
}

.cg-price-wrap .cg-price-btn {
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  border-radius: 100px;
  color: var(--color-white);
  border: 1px solid var(--color-blue);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  padding: 11px 5px;
  -webkit-transition: all .4s linear;
  transition: all .4s linear;
}

.cg-price-wrap:hover {
  background: linear-gradient(216deg, rgba(255, 255, 255, 0) -50.46%, rgba(255, 255, 255, 0.2) 4.53%, rgba(255, 255, 255, 0) 55.58%);
}

.cg-price-wrap:hover .cg-price-box::before {
  height: 100%;
}

.cg-price-wrap:hover .cg-price-box .cg-price-btn {
  background-color: var(--color-white);
  color: var(--color-blue);
}

.cg-price-wrap:hover .cg-price-box .cg-price-value-box .cg-price-plan svg, .cg-price-wrap:hover .cg-price-box .cg-price-value-box .cg-price-plan i {
  color: var(--color-blue);
}

@-webkit-keyframes priceFade {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes priceFade {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/*================================================
15. Choose CSS
=================================================*/
.choose-card-body {
  height: 100%;
}

.choose-card-body .icon-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  background: #0c0d25;
  width: 104px;
  height: 104px;
  margin: 0 auto 32px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

@media only screen and (max-width: 991.98px) {
  .choose-card-body .icon-card {
    margin: 0 auto 16px;
  }
}

.choose-card-body .choose-card-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 6px;
}

.choose-card-body .choose-card-desc {
  max-width: 370px;
  margin: 0 auto;
}

.choose-us-img {
  -webkit-filter: brightness(90%) contrast(110%) saturate(80%);
          filter: brightness(90%) contrast(110%) saturate(80%);
}

.ai-img-choose-box {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #FFF;
  -webkit-box-shadow: 8px 8px 0px 0px rgba(3, 24, 17, 0.08);
          box-shadow: 8px 8px 0px 0px rgba(3, 24, 17, 0.08);
  padding: 56px 28px;
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.ai-img-choose-box h3 {
  margin: 28px 0 16px;
}

.ai-img-choose-box:hover {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
}

.choose-img-bg-1 {
  bottom: 200px;
}

.choose-img-bg-2 {
  right: 20px;
  top: 43px;
  -webkit-animation: slide_2 18s ease-in-out alternate infinite;
          animation: slide_2 18s ease-in-out alternate infinite;
}

@-webkit-keyframes slide_2 {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
            transform: translate(0) rotate(0deg);
  }
  15% {
    -webkit-transform: translate(10px, 10px) rotate(15deg);
            transform: translate(10px, 10px) rotate(15deg);
  }
  30% {
    -webkit-transform: translate(10px, -15px) rotate(0deg);
            transform: translate(10px, -15px) rotate(0deg);
  }
  45% {
    -webkit-transform: translate(-10px, -25px) rotate(-10deg);
            transform: translate(-10px, -25px) rotate(-10deg);
  }
  60% {
    -webkit-transform: translate(0, 25px) rotate(15deg);
            transform: translate(0, 25px) rotate(15deg);
  }
  75% {
    -webkit-transform: translate(-10px, -15px) rotate(10deg);
            transform: translate(-10px, -15px) rotate(10deg);
  }
  90% {
    -webkit-transform: translate(-5, -5px) rotate(-10deg);
            transform: translate(-5, -5px) rotate(-10deg);
  }
  100% {
    -webkit-transform: translate(0) rotate(0deg);
            transform: translate(0) rotate(0deg);
  }
}

@keyframes slide_2 {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
            transform: translate(0) rotate(0deg);
  }
  15% {
    -webkit-transform: translate(10px, 10px) rotate(15deg);
            transform: translate(10px, 10px) rotate(15deg);
  }
  30% {
    -webkit-transform: translate(10px, -15px) rotate(0deg);
            transform: translate(10px, -15px) rotate(0deg);
  }
  45% {
    -webkit-transform: translate(-10px, -25px) rotate(-10deg);
            transform: translate(-10px, -25px) rotate(-10deg);
  }
  60% {
    -webkit-transform: translate(0, 25px) rotate(15deg);
            transform: translate(0, 25px) rotate(15deg);
  }
  75% {
    -webkit-transform: translate(-10px, -15px) rotate(10deg);
            transform: translate(-10px, -15px) rotate(10deg);
  }
  90% {
    -webkit-transform: translate(-5, -5px) rotate(-10deg);
            transform: translate(-5, -5px) rotate(-10deg);
  }
  100% {
    -webkit-transform: translate(0) rotate(0deg);
            transform: translate(0) rotate(0deg);
  }
}

.cs-choose-card-body {
  height: 100%;
  background: linear-gradient(44deg, #11192c 65.66%, rgba(17, 25, 44, 0) 99.16%), url(../images/shapes/pattern-masked-1.svg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 40px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.cs-choose-card-body .cs-icon-card {
  margin-bottom: 32px;
}

.cs-choose-card-body:hover {
  background: linear-gradient(44deg, #11192c 65.66%, rgba(17, 25, 44, 0) 99.16%), url(../images/shapes/pattern-masked-2.svg);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

@media only screen and (max-width: 1199.98px) {
  .cs-choose-card-body {
    padding: 20px;
  }
  .cs-choose-card-body .cs-icon-card {
    margin-bottom: 22px;
  }
}

/*================================================
16. Team CSS
=================================================*/
.team-box-wrap {
  border-radius: 20px;
  padding: 1px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(33.13%, rgba(255, 255, 255, 0.25)), color-stop(91.05%, var(--color-deep-blue)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 33.13%, var(--color-deep-blue) 91.05%);
}

.team-box-wrap .team-box {
  background-color: var(--color-deep-blue);
  border-radius: 20px;
}

.team-box-wrap .team-box .team-img {
  padding-top: 40px;
}

@media only screen and (max-width: 1199.98px) {
  .team-box-wrap .team-box .team-img {
    padding-top: 20px;
  }
}

.team-box-wrap .team-box .team-img img {
  -webkit-transition-duration: .8s;
          transition-duration: .8s;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.team-box-wrap .team-box .team-img .team-info-wrap {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(33.13%, rgba(0, 0, 29, 0)), color-stop(91.05%, var(--color-deep-blue)));
  background: linear-gradient(180deg, rgba(0, 0, 29, 0) 33.13%, var(--color-deep-blue) 91.05%);
  padding: 40px;
}

@media only screen and (max-width: 1199.98px) {
  .team-box-wrap .team-box .team-img .team-info-wrap {
    padding: 20px;
  }
}

.team-box-wrap .team-box .team-img .team-info-wrap .team-info h2 {
  font-size: 20px;
}

@media only screen and (max-width: 1199.98px) {
  .team-box-wrap .team-box .team-img .team-info-wrap .team-info p {
    font-size: 14px;
  }
}

.team-box-wrap .team-box .team-img .team-info-wrap .team-social {
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.06);
  width: 32px;
  height: 32px;
  padding: 4px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  margin-left: 15px;
}

.team-box-wrap .team-box .team-img .team-info-wrap .team-social svg, .team-box-wrap .team-box .team-img .team-info-wrap .team-social i {
  color: var(--color-white);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.team-box-wrap .team-box:hover .team-img img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.team-box-wrap .team-box:hover .team-img .team-info-wrap .team-social {
  background: var(--color-light-black);
  -webkit-transform: rotate3d(1, 1, 1, 360deg);
          transform: rotate3d(1, 1, 1, 360deg);
}

.team-personal-data .team-dev-head {
  margin-bottom: 90px;
}

@media only screen and (max-width: 991.98px) {
  .team-personal-data .team-dev-head {
    margin-bottom: 60px;
  }
}

.team-personal-data .team-dev-head .team-dev-name {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2em;
  text-transform: capitalize;
  margin-bottom: 8px;
}

@media only screen and (max-width: 991.98px) {
  .team-personal-data .team-dev-head .team-dev-name {
    font-size: 35px;
  }
}

.team-personal-data .team-dev-head .team-dev-title {
  font-family: var(--ff-inter-tight);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.36em;
}

.team-personal-data .team-dev-head .team-dev-desc {
  margin-top: 40px;
  font-family: var(--ff-inter-tight);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.77em;
}

@media only screen and (max-width: 991.98px) {
  .team-personal-data .team-dev-head .team-dev-desc {
    margin-top: 30px;
  }
}

.team-personal-data .team-dev-head .dev-overview-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 48px;
  margin-top: 48px;
}

@media only screen and (max-width: 991.98px) {
  .team-personal-data .team-dev-head .dev-overview-data {
    margin-top: 30px;
  }
}

.team-personal-data .team-dev-head .dev-overview-data p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 20px;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.77em;
}

.team-personal-data .team-dev-head .dev-overview-data p svg {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 991.98px) {
  .team-personal-data .team-dev-head .dev-overview-data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 20px;
    margin-top: 30px;
  }
}

.team-dev-skills {
  margin-bottom: 90px;
}

@media only screen and (max-width: 991.98px) {
  .team-dev-skills {
    margin-bottom: 60px;
  }
}

.team-dev-skills .dev-skills-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5em;
  text-transform: capitalize;
  margin-bottom: 28px;
}

.team-dev-skills .dev-skills-desc {
  font-family: var(--ff-inter-tight);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7em;
}

.team-dev-skills .dev-skills-tags {
  margin-top: 48px;
}

.team-dev-skills .dev-skills-tags .tag-list {
  gap: 20px;
}

.team-dev-skills .dev-skills-tags .tag-list li {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  font-family: var(--ff-inter);
  font-style: italic;
  font-weight: 500;
  padding: 13px 28px;
  color: var(--color-white);
}

@media only screen and (max-width: 991.98px) {
  .team-dev-skills .dev-skills-tags .tag-list li {
    padding: 8px 15px;
  }
}

.team-dev-social .dev-social-title {
  margin-bottom: 28px;
  font-size: 32px;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
}

/*================================================
17. Error CSS
=================================================*/
.common-bg-area {
  padding: 300px 0 50px;
  background: url(../images/error-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 991.98px) {
  .common-bg-area {
    padding: 180px 0 0px;
  }
}

.error-info {
  gap: 64px;
}

@media only screen and (max-width: 991.98px) {
  .error-info {
    gap: 44px;
  }
}

/*================================================
18. Works CSS
=================================================*/
.work-step-wrap .work-step-box {
  padding-left: 42px;
  position: relative;
}

.work-step-wrap .work-step-box::before {
  position: absolute;
  left: 0;
  top: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FA7F27;
  content: "";
}

.work-step-wrap .work-step-box:nth-child(2)::before {
  background-color: #4B62E6;
}

.work-step-wrap .work-step-box:nth-child(3)::before {
  background-color: #F786BE;
}

.work-step-wrap .work-step-box .section-title.border-style .border-sbu {
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.work-step-wrap .work-step-box .section-title.border-style .title {
  font-size: 30px;
  line-height: 1.6;
}

@media only screen and (max-width: 991.98px) {
  .work-step-wrap .work-step-box .section-title.border-style .title {
    font-size: 20px;
  }
}

.work-step-wrap .work-step-box .section-title .desc {
  margin-top: 20px;
}

@media only screen and (max-width: 991.98px) {
  .work-step-wrap .work-step-box .section-title .desc {
    margin-top: 10px;
  }
}

.work-step-wrap .work-step-box:not(:last-child) {
  padding-bottom: 56px;
}

@media only screen and (max-width: 991.98px) {
  .work-step-wrap .work-step-box:not(:last-child) {
    padding-bottom: 30px;
  }
}

.work-step-wrap .work-step-box:not(:last-child)::after {
  position: absolute;
  left: 9px;
  top: 4px;
  width: 1px;
  height: 100%;
  border: 1px dashed #C0C5C3;
  content: "";
  z-index: -1;
}

.cg-work-box-wrap {
  background: -webkit-gradient(linear, right top, left top, color-stop(0.08%, rgba(255, 255, 255, 0)), color-stop(51.85%, rgba(255, 255, 255, 0.25)), color-stop(99.92%, rgba(255, 255, 255, 0)));
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0.08%, rgba(255, 255, 255, 0.25) 51.85%, rgba(255, 255, 255, 0) 99.92%);
  padding: 1.5px;
  border-radius: 12px;
  -webkit-transition: all .4s linear;
  transition: all .4s linear;
}

.cg-work-box-wrap .cg-work-box {
  background-color: #181f2f;
  border-radius: 12px;
  padding: 50px 0px 0 50px;
  gap: 126px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

@media only screen and (max-width: 1399.98px) {
  .cg-work-box-wrap .cg-work-box {
    gap: 50px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .cg-work-box-wrap .cg-work-box {
    padding: 30px;
  }
}

@media only screen and (max-width: 991.98px) {
  .cg-work-box-wrap .cg-work-box {
    gap: 30px;
  }
}

.cg-work-box-wrap .cg-work-box .cg-work-info {
  max-width: 499px;
  width: 100%;
}

.cg-work-box-wrap .cg-work-box .cg-work-info span {
  font-size: 24px;
  font-weight: 500;
  background-color: #1B7CD4;
  border-radius: 12px;
  line-height: 1;
  padding: 8px 18px;
}

@media only screen and (max-width: 991.98px) {
  .cg-work-box-wrap .cg-work-box .cg-work-info span {
    font-size: 18px;
  }
}

.cg-work-box-wrap .cg-work-box .cg-work-info h2 {
  font-size: 32px;
  font-weight: 500;
  margin: 16px 0 20px;
}

@media only screen and (max-width: 991.98px) {
  .cg-work-box-wrap .cg-work-box .cg-work-info h2 {
    font-size: 20px;
    margin: 15px 0 10px;
  }
}

.cg-work-box-wrap .cg-work-box .cg-work-img {
  padding: 32px 0px 0 32px;
  background-image: url(../images/works/bg-sp-1.png);
  border-top-left-radius: 28px;
  max-width: 643px;
  width: 100%;
}

@media only screen and (max-width: 1399.98px) {
  .cg-work-box-wrap .cg-work-box .cg-work-img {
    max-width: 530px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .cg-work-box-wrap .cg-work-box .cg-work-img {
    border-radius: 40px 0px 28px 0px;
    padding: 22px 0px 0 22px;
  }
}

.cg-work-box-wrap .cg-work-box .cg-work-img img {
  border-top-left-radius: 28px;
}

.cg-work-box-wrap:hover {
  background: -webkit-gradient(linear, right top, left top, color-stop(0.08%, rgba(255, 255, 255, 0)), color-stop(51.85%, rgba(255, 255, 255, 0.4)), color-stop(99.92%, rgba(255, 255, 255, 0)));
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0.08%, rgba(255, 255, 255, 0.4) 51.85%, rgba(255, 255, 255, 0) 99.92%);
}

.cg-work-box-wrap:hover .cg-work-box {
  background-color: rgba(24, 31, 47, 0.5);
}

.cg-work-box-area-wrap .single-cg-work-box-area:nth-child(2) .cg-work-box-wrap .cg-work-box .cg-work-info span {
  background-color: #6354AC;
}

.cg-work-box-area-wrap .single-cg-work-box-area:nth-child(2) .cg-work-box-wrap .cg-work-box .cg-work-img {
  background-image: url(../images/works/bg-sp-2.png);
}

.cg-work-box-area-wrap .single-cg-work-box-area:nth-child(3) .cg-work-box-wrap .cg-work-box .cg-work-info span {
  background-color: #03B0D5;
}

.cg-work-box-area-wrap .single-cg-work-box-area:nth-child(3) .cg-work-box-wrap .cg-work-box .cg-work-img {
  background-image: url(../images/works/bg-sp-3.png);
}

/*================================================
19. Showcase CSS
=================================================*/
.work-mixi .mix {
  display: none;
}

.showcase-list {
  gap: 24px;
}

.showcase-list li {
  font-size: 22px;
  border-radius: 50px;
  color: var(--color-text-heading);
  border: 1px solid transparent;
  padding: 2px 24px;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.showcase-list li.active {
  background: rgba(3, 24, 17, 0.03);
  color: var(--primary-green);
  border: 1px solid var(--primary-green);
}

@media only screen and (max-width: 1399.98px) {
  .showcase-list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

@media only screen and (max-width: 991.98px) {
  .showcase-list {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 5px;
  }
  .showcase-list li {
    font-size: 18px;
  }
}

.single-showcase-img {
  border-radius: 24px;
  overflow: hidden;
}

.single-showcase-img img {
  border-radius: 24px;
  -webkit-transition: -webkit-filter 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: -webkit-filter 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: filter 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: filter 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), -webkit-filter 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  will-change: filter;
}

.single-showcase-img:hover img {
  -webkit-filter: brightness(120%) hue-rotate(45deg);
          filter: brightness(120%) hue-rotate(45deg);
}

.slider-button-wrap .swiper-pagination-bullets.swiper-pagination-horizontal {
  width: auto;
}

.slider-button-wrap .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}

.slider-button-wrap .common-pagination {
  gap: 10px;
}

.slider-button-wrap .common-pagination span {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 1px solid transparent;
  opacity: 1;
  position: relative;
}

.slider-button-wrap .common-pagination span::before {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #D9D9D9;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  content: "";
  border-radius: 50%;
}

.slider-button-wrap .common-pagination span.swiper-pagination-bullet-active {
  background-color: rgba(29, 158, 67, 0.1);
  border: 1px solid var(--primary-green);
}

.slider-button-wrap .common-pagination span.swiper-pagination-bullet-active::before {
  background-color: var(--primary-green);
}

.slider-button-wrap .slide-btn-prev, .slider-button-wrap .slide-btn-next {
  color: rgba(3, 24, 17, 0.5);
  -webkit-transition: all .3s;
  transition: all .3s;
}

.slider-button-wrap .slide-btn-prev:hover, .slider-button-wrap .slide-btn-next:hover {
  color: var(--color-text-heading);
}

.slider-button-wrap.slider-button-wrap-blue .common-pagination {
  gap: 10px;
}

.slider-button-wrap.slider-button-wrap-blue .common-pagination span {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 1px solid transparent;
  opacity: 1;
  position: relative;
}

.slider-button-wrap.slider-button-wrap-blue .common-pagination span::before {
  background-color: #5F50D5;
}

.slider-button-wrap.slider-button-wrap-blue .common-pagination span.swiper-pagination-bullet-active {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(34.9%, rgba(56, 57, 86, 0.4)), to(rgba(56, 57, 86, 0.16)));
  background: linear-gradient(180deg, rgba(56, 57, 86, 0.4) 34.9%, rgba(56, 57, 86, 0.16) 100%);
  border: 1px solid var(--color-light-white);
}

.slider-button-wrap.slider-button-wrap-blue .common-pagination span.swiper-pagination-bullet-active::before {
  background-color: var(--color-white);
}

.slider-button-wrap.slider-button-wrap-blue .slide-btn-prev, .slider-button-wrap.slider-button-wrap-blue .slide-btn-next {
  color: rgba(255, 255, 255, 0.25);
}

.slider-button-wrap.slider-button-wrap-blue .slide-btn-prev:hover, .slider-button-wrap.slider-button-wrap-blue .slide-btn-next:hover {
  color: white;
}

.cg-testimonial-slide {
  padding-top: 38px;
}

.cg-testimonial-slide .swiper-slide {
  min-height: 100%;
  height: unset;
}

.cg-testimonial-sp {
  bottom: 91px;
}

/*================================================
20. Features CSS
=================================================*/
.cg-features-box .cg-features-icon-wrap {
  background: linear-gradient(135deg, rgba(66, 41, 255, 0.25) 34.78%, rgba(66, 41, 255, 0.25) 45.56%, rgba(66, 41, 255, 0.25) 67.43%);
  max-width: 340px;
  padding: 1.5px;
  border-radius: 10px;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.cg-features-box .cg-features-icon-wrap::before {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 313%;
  width: 90%;
  background: transparent;
  -webkit-transition: all .6s linear;
  transition: all .6s linear;
  content: "";
  z-index: 1;
  -webkit-animation: animate_border 4.5s linear infinite;
          animation: animate_border 4.5s linear infinite;
}

.cg-features-box .cg-features-icon-wrap .cg-features-icon {
  background-color: #111725;
  position: relative;
  z-index: 1;
  padding: 50px 20px;
  border-radius: 10px;
}

.cg-features-box .cg-features-info h2 {
  margin: 32px 0 12px;
}

.cg-features-box .cg-features-info p {
  max-width: 408px;
}

.cg-features-box:hover .cg-features-icon-wrap::before {
  width: 190%;
  background: linear-gradient(135deg, rgba(66, 41, 255, 0.5) 34.78%, rgba(255, 255, 255, 0.5) 45.56%, rgba(66, 41, 255, 0.5) 67.43%);
}

@-webkit-keyframes animate_border {
  from {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
            transform: translate(-50%, -50%) rotateZ(0deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
            transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes animate_border {
  from {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
            transform: translate(-50%, -50%) rotateZ(0deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
            transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

.cs-feature-wrap {
  max-width: 1620px;
  border-radius: 80px;
}

@media only screen and (max-width: 1199.98px) {
  .cs-feature-wrap {
    border-radius: 40px;
  }
}

.cs-feature-box {
  background-color: #F6F7F9;
  border-radius: 40px;
  gap: 48px;
  padding: 40px 40px 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

@media only screen and (max-width: 991.98px) {
  .cs-feature-box {
    gap: 30px;
    padding: 25px;
    border-radius: 20px;
  }
}

.cs-feature-box .cs-feature-info h2 {
  font-size: 32px;
  line-height: 1.5;
}

@media only screen and (max-width: 991.98px) {
  .cs-feature-box .cs-feature-info h2 {
    font-size: 24px;
  }
}

.cs-feature-box .cs-feature-info p {
  margin-top: 20px;
}

@media only screen and (max-width: 991.98px) {
  .cs-feature-box .cs-feature-info p {
    margin-top: 10px;
  }
}

.cs-feature-box .cs-feature-info .cs-feature-icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #19AACD;
  margin-bottom: 30px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

@media only screen and (max-width: 991.98px) {
  .cs-feature-box .cs-feature-info .cs-feature-icon {
    margin-bottom: 20px;
  }
}

.cs-feature-box:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.cs-feature-box:hover .cs-feature-info .cs-feature-icon {
  background-color: var(--primary-purple);
}

.cs-feature-box.cs-feature-box-two {
  padding: 40px 32px;
}

@media only screen and (max-width: 991.98px) {
  .cs-feature-box.cs-feature-box-two {
    padding: 25px;
  }
}

.cs-feature-box.cs-feature-box-two .cs-feature-img {
  max-width: 568px;
}

@media only screen and (max-width: 1199.98px) {
  .cs-feature-box.cs-feature-box-two .cs-feature-img {
    max-width: 458px;
  }
}

.cs-feature-box.cs-feature-box-three {
  padding: 40px 16px 16px;
}

@media only screen and (max-width: 991.98px) {
  .cs-feature-box.cs-feature-box-three {
    padding: 25px;
  }
}

.cs-feature-box.cs-feature-box-three .cs-feature-info {
  padding: 0 24px;
}

@media only screen and (max-width: 991.98px) {
  .cs-feature-box.cs-feature-box-three .cs-feature-info {
    padding: 0;
  }
}

/*================================================
21. Creativity CSS
=================================================*/
.creativity-wrap {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  justify-items: stretch;
  -ms-flex-line-pack: space-evenly;
      align-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 145px;
}

@media only screen and (max-width: 1399.98px) {
  .creativity-wrap {
    grid-gap: 100px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .creativity-wrap {
    grid-gap: 24px;
  }
}

@media only screen and (max-width: 991.98px) {
  .creativity-wrap {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767.98px) {
  .creativity-wrap {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}

.creativity-box {
  max-width: 343px;
}

.creativity-box .creativity-icon-wrap {
  border-radius: 20px;
  padding: 1.5px;
  position: relative;
  z-index: 1;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.5) 45.56%, rgba(66, 41, 255, 0.5) 67.43%);
}

.creativity-box .creativity-icon-wrap .creativity-icon {
  width: 86px;
  height: 86px;
  background: #1A1E30;
  color: #fff;
  border-radius: 20px;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.creativity-box .creativity-info h2 {
  margin: 36px 0 10px;
}

.creativity-box .creativity-info p {
  font-size: 18px;
  line-height: 177.778%;
}

.creativity-box:hover .creativity-icon-wrap {
  background: linear-gradient(308deg, rgba(255, 255, 255, 0.5) 45.56%, rgba(66, 41, 255, 0.5) 67.43%);
}

.creativity-box:hover .creativity-icon-wrap .creativity-icon {
  color: #5a45f9;
}

.cg-creativity-area {
  background: -webkit-gradient(linear, left top, left bottom, from(#070B17), to(rgba(7, 11, 23, 0.95)));
  background: linear-gradient(180deg, #070B17 0%, rgba(7, 11, 23, 0.95) 100%);
}

/*================================================
22. Form CSS
=================================================*/
.form-wrap {
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #0e0f23;
  padding: 40px;
  max-width: 731px;
}

@media only screen and (max-width: 991.98px) {
  .form-wrap {
    padding: 40px 20px;
  }
}

.form-wrap .common-form-box .form-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 120.833%;
  text-transform: capitalize;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 1199.98px) {
  .form-wrap .common-form-box .form-title {
    font-size: 38px;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
}

.form-wrap .common-form-box .input-group label {
  color: #fff;
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 6px;
}

.form-wrap .common-form-box .input-group input, .form-wrap .common-form-box .input-group textarea {
  width: 100%;
  background: #0e0f23;
  border-radius: 16px !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px 20px;
  color: #fff;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}

.form-wrap .common-form-box .input-group input::-webkit-input-placeholder, .form-wrap .common-form-box .input-group textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
}

.form-wrap .common-form-box .input-group input:-ms-input-placeholder, .form-wrap .common-form-box .input-group textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
}

.form-wrap .common-form-box .input-group input::-ms-input-placeholder, .form-wrap .common-form-box .input-group textarea::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
}

.form-wrap .common-form-box .input-group input::placeholder, .form-wrap .common-form-box .input-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
}

.form-wrap .common-form-box .input-group input:focus, .form-wrap .common-form-box .input-group textarea:focus {
  border-color: var(--color-blue);
  outline: none;
}

.form-wrap.form-wrap-contact {
  max-width: unset;
}

.form-wrap.form-wrap-contact .common-form-box .form-title {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: unset;
}

.form-wrap.form-wrap-contact .common-form-box .form-info {
  margin: 12px 0 48px;
  font-size: 18px;
}

@media only screen and (max-width: 991.98px) {
  .form-wrap.form-wrap-contact .common-form-box .form-info {
    margin: 12px 0 30px;
  }
}

.check-group {
  display: block;
  margin: 0px 0 40px;
}

.check-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check-group label {
  position: relative;
  cursor: pointer;
  line-height: 0;
}

.check-group label::before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1.5px solid rgba(255, 255, 255, 0.5);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
  border-radius: 6px;
}

.check-group label a {
  color: var(--color-white);
  border-bottom: 1px solid;
  text-transform: capitalize;
}

.check-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid rgba(255, 255, 255, 0.7);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.form-info-wrap .common-btn {
  padding: 20px 22px;
}

.form-info-wrap .common-btn.border-style {
  border: 1px solid var(--color-blue);
}

.login-link {
  color: var(--color-blue);
}

.login-link:hover {
  color: var(--color-blue);
}

/*================================================
23. Terms and Condition CSS
=================================================*/
.condition-content:not(:last-child) {
  margin-bottom: 48px;
}

@media only screen and (max-width: 991.98px) {
  .condition-content:not(:last-child) {
    margin-bottom: 30px;
  }
}

.condition-content .terms-desc {
  font-size: 18px;
  line-height: 1.7;
}

@media only screen and (max-width: 991.98px) {
  .condition-content .terms-desc {
    font-size: 16px;
  }
}

.condition-content .terms-inner-desc {
  margin-top: 20px;
}

@media only screen and (max-width: 991.98px) {
  .condition-content .terms-inner-desc {
    margin-top: 10px;
  }
}

.terms-title {
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
}

@media only screen and (max-width: 991.98px) {
  .terms-title {
    margin-bottom: 16px;
    font-size: 22px;
  }
}

.table-content ul li:not(:last-child) {
  margin-bottom: 12px;
}

.table-content ul li a {
  font-family: var(--ff-inter);
  color: var(--color-white);
  font-size: 20px;
  line-height: 190%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.table-content ul li a:hover {
  color: var(--color-light-white);
}

.italic-font {
  font-style: italic;
}

/*================================================
24. Contact CSS
=================================================*/
.contact-card-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contact-card-box .contact-card-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  width: 64px;
  height: 64px;
  background: var(--color-blue);
  border: none;
  border-radius: 50%;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.contact-card-box .contact-card-icon svg, .contact-card-box .contact-card-icon i {
  color: var(--color-white);
}

.contact-card-box .contact-card-text {
  max-width: 280px;
  margin: 0 auto;
}

.contact-card-box .contact-card-text .contact-card-title {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.contact-card-box .contact-card-text .contact-card-desc {
  font-family: var(--ff-inter-tight);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.contact-card-box:hover .contact-card-icon {
  background: var(--primary-green);
}

.contact-hero {
  background-image: url(../images/contact.png);
  border-radius: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.map-wrap {
  width: 100%;
  height: 560px;
}

.map-wrap iframe {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 767.98px) {
  .map-wrap {
    height: 250px;
  }
}

@media only screen and (max-width: 575.98px) {
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
}

.mobile-hide.d-flex {
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

.input-group {
  .intl-tel-input.allow-dropdown  {
    width: 100%;
    .flag-container {
      .selected-flag {
        background: #16183d;
        border-radius: 16px 0 0 16px;
      }
      .arrow {
        color: #fff;
      }
    }
    input[type="tel"] {
      padding-left: 52px;
    }
  }
}