.create-component {
  grid-template: "client-tab-rail client-workspace" / 360px auto;
  grid-area: client-layout-wrapper;
  width: 100vw;
  height: calc(100vh - 66px);
  display: grid;
}

.create-component-aside {
  padding: 20px 25px;
  overflow: auto;
  background-color: #fff;
  border-right: 1px solid #e6e6e6;
}
.create-component-main {
  padding: 30px;
  overflow: auto;
  width: 100%;
}