.app-root {
  
}
.app-layout {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.app-layout-wrapper {
  grid-template: "client-tab-rail client-workspace" / 320px auto;
  grid-area: client-layout-wrapper;
  width: 100vw;
  height: calc(100vh - 66px);
  display: grid;
}