.template-iframe {
  width: 100%;
  height: 100%;
  display: block;
}
.loader-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Set height to full viewport height */
}