@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimProLight.eot');
    src: url('GTWalsheimProLight.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimProLight.woff') format('woff'),
        url('GTWalsheimProLight.ttf') format('truetype'),
        url('GTWalsheimProLight.svg#GTWalsheimProLight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimProRegular.eot');
    src: url('GTWalsheimProRegular.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimProRegular.woff') format('woff'),
        url('GTWalsheimProRegular.ttf') format('truetype'),
        url('GTWalsheimProRegular.svg#GTWalsheimProRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimProBold.eot');
    src: url('GTWalsheimProBold.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimProBold.woff') format('woff'),
        url('GTWalsheimProBold.ttf') format('truetype'),
        url('GTWalsheimProBold.svg#GTWalsheimProBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimProMedium.eot');
    src: url('GTWalsheimProMedium.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimProMedium.woff') format('woff'),
        url('GTWalsheimProMedium.ttf') format('truetype'),
        url('GTWalsheimProMedium.svg#GTWalsheimProMedium') format('svg');
    font-weight: 500;
    font-style: normal;
}
