@import "./variable.scss";
@import "./fonts.scss";

// Variable CSS
:root {
  // Global
  --global-primary-font: "Cerebri Sans", sans-serif;
  --global-secondary-font: "Plus Jakarta Sans", sans-serif;
  --global-system-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --global-primary-color: #4c47cc;
  --global-primary-hover-color: #3531af;
  --global-primary-disabled-color: #79789b;

  --global-secondary-color: #1e325c;
  --global-secondary-hover-color: #3531af;
  --global-secondary-disabled-color: #79789b;

  --global-default-color: #f0f2f4;
  --global-default-hover-color: #d3d9de;
  --global-default-disabled-color: #f0f2f4;

  --global-light-color: #fff;
  --global-dark-color: #000;
  --global-tertiary-color: #1e325c;
  --global-warning-color: #ffcc00;
  --global-success-color: #339900;
  --global-error-color: #ea1414;
  --global-width: 100%;
  --global-height: 100%;

  --global-blue-dark: #4462ef;
  --global-blue-light: #ededfa;
  --global-pink-dark: #ef4e77;
  --global-pink-light: #fcdce4;
  --global-green-dark: #149f7f;
  --global-green-light: #d1f4ec;
  --global-yellow-dark: #d98c4c;
  --global-yellow-light: #fceddc;
  --global-purple-dark: #706cd6;
  --global-purple-light: #dbdaf5;
  --global-cyan-dark: #25b3cc;
  --global-cyan-light: #c0ecf3;

  --global-font-size: 1rem;
  --global-border-radius: 6px;
  --global-border-color: #e6e6e6;
  --global-text-color: #1d1c1d;
  --global-header-color: #070808;
  --global-status-color: #2bac76;
  --global-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14),
    inset 0 0 0px 2px transparent;
}

@media (prefers-color-scheme: dark) {
}

// Variable Css

body {
  font-family: var( --global-primary-font);
  background-color: #FAFAFA;
}

a {
  color: #030dcf;
}

.cursor-pointer {
  cursor: pointer;
}
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  .required {
    color: var(--global-error-color);
  }
}

.form-control {
  font-family: var(--global-primary-font);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  min-height: 46px;
  border-radius: 6px;
  padding: 6px 14px;
  color: #000;
  background-color: #fff;
  border: 1px solid #e4e9f4;
  &::placeholder {
    color: #5e687c;
  }
  &:focus {
    border: 1px solid #3866e6;
    box-shadow: none;
  }
}
.form-control-md {
  font-size: 14px;
  min-height: 40px;
}
.form-control-sm {
  font-size: 13px;
  min-height: 36px;
  padding: 3px 2.25rem 3px 14px;
}
.form-control-xs {
  font-size: 12px;
  min-height: 30px;
  padding: 3px 2.25rem 3px 12px;
}

.form-feedback-error {
  color: var(--global-error-color);
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
}

.form-note {
  font-size: 13px;
  margin-top: 5px;
  font-weight: 500;
}

textarea.form-control {
  padding: 12px 14px;
}

select,
.form-select {
  appearance: none;
  &:focus {
    border: 1px solid #3866e6;
    box-shadow: none;
  }
}

.btn {
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  border-radius: var(--global-border-radius);
  padding: 8px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn.disabled,
.btn:disabled {
  pointer-events: none;
  color: #212529;
  background-color: #f0f2f4;
  border-color: #f0f2f4;
}
.btn-xs {
  font-size: 12px;
  padding: 6px 12px;
}
.btn-sm {
  font-size: 13px;
  min-height: 30px;
}
.btn-md {
  font-size: 14px;
  min-height: 45px;
}
.btn-lg {
  font-size: 16px;
  min-height: 50px;
}
.btn-xl {
  font-size: 16px;
  min-height: 55px;
}

.btn-icon-left {
  margin-right: 8px;
}
.btn-icon-right {
  margin-left: 8px;
}

.btn-global-primary {
  color: #fff;
  background-color: var(--global-primary-color);
  border-color: var(--global-primary-color);
  &:hover {
    color: #fff;
    background-color: var(--global-primary-hover-color);
    border-color: var(--global-primary-hover-color);
  }
  &:first-child {
    &:active {
      color: #fff;
      background-color: var(--global-primary-hover-color);
      border-color: var(--global-primary-hover-color);
    }
  }
  &.disabled,
  &:disabled {
    color: #fff;
    background-color: var(--global-primary-disabled-color);
    border-color: var(--global-primary-disabled-color);
    pointer-events: none;
  }
}
.btn-global-secondary {
  color: #212529;
  background-color: #f0f2f4;
  border-color: #f0f2f4;
  &:hover,
  &:focus,
  &:active {
    color: #212529;
    background-color: #f0f2f4;
    border-color: #f0f2f4;
  }
}
.btn-global-default {
  color: var(--global-text-color);
  background-color: var(--global-default-color);
  border-color: var(--global-default-color);
  &:hover,
  &:focus,
  &:active {
    color: var(--global-text-color);
    background-color: var(--global-default-hover-color);
    border-color: var(--global-default-hover-color);
  }
}
.btn-global-danger {
  color: #fff;
  background-color: #ea1414;
  border-color: #ea1414;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: #ea1414;
    border-color: #ea1414;
  }
}
.btn-global-dark {
  color: #212529;
  background-color: #f0f2f4;
  border-color: #f0f2f4;
  &:hover,
  &:focus,
  &:active {
    color: #212529;
    background-color: #f0f2f4;
    border-color: #f0f2f4;
  }
}

.btn-global-light {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  &:hover,
  &:focus,
  &:active {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
}
.btn-global-link {
  color: var(--global-primary-color);
  background-color: transparent;
  border-color: transparent;
  &:hover,
  &:focus,
  &:active {
    color: var(--global-primary-color);
    background-color: transparent;
    border-color: transparent;
  }
}
.btn-global-danger-link {
  color: #ea1414;
  background-color: transparent;
  border-color: transparent;
  &:hover,
  &:focus,
  &:active {
    color: #ea1414;
    background-color: transparent;
    border-color: transparent;
  }
}

.btn-link {
  color: #030dcf;
  text-decoration: none;
}

// Global Css

// React Modal Css
.ReactModal__Body--open {
  overflow: hidden;
  // padding-right: 17px;
}

.ReactModal__Body--open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  // z-index: 1024;
  z-index: 999;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal {
  background: rgba(0, 0, 0, 0.8);
}

.ReactModal__Overlay--after-open.modal {
  display: block;
}

.react-modal {
  .modal-content {
    border: 0;
    border-radius: 12px;
  }

  .modal-dialog {
    max-width: 600px;

    @media (min-width: 576px) {
      margin: 1.75rem auto;
    }

    &.react-modal-dialog-fit {
      max-width: fit-content;
    }
    &.react-modal-dialog-xxs {
      max-width: 344px;
    }
    &.react-modal-dialog-xs {
      max-width: 420px;
    }

    &.react-modal-dialog-sm {
      max-width: 700px;
    }

    &.react-modal-dialog-sm-md {
      max-width: 850px;
    }

    &.react-modal-dialog-md {
      max-width: 960px;
    }

    &.react-modal-dialog-lg {
      max-width: 1024px;
    }

    &.react-modal-dialog-xl {
      max-width: 1200px;
    }
    &.react-modal-dialog-xxl {
      max-width: 1400px;
    }
    &.react-modal-dialog-full {
      max-width: 100%;
    }
  }
}

.react-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  position: relative;
  background-color: #f0f2f4;
  border-radius: 12px 12px 0 0;
  .react-modal-close {
    position: absolute;
    top: 50%;
    right: 15px;
    z-index: 1;
    transform: translateY(-50%);
    padding: 5px;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    &:hover {
      background-color: #e6e6e6;
    }
  }
}

.react-modal-title {
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  color: #192a4d;
  margin-bottom: 0;
}

.react-modal-body {
  padding: 20px;
  &.react-modal-body-scrollable {
    max-height: calc(100vh - 194.4px);
    overflow: auto;
    // @extend .styled-scroll;
  }
}
.react-modal-body-content {
}

.react-modal-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--main-black);
}

.react-modal-body-text {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: var(--main-black);
}

.react-modal-footer {
  padding: 15px 20px;
  border-radius: 0 0 12px 12px;
  background: var(--main-white);
  border-top: 1px solid var(--border-light);
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  .btn {
    min-width: 100px;
  }
}

// React Modal Css

// Dropzone
.dropzone {
  .dropzone-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    color: #333;
    border: 2px dashed #eeeeee;
    background-color: #fafafa;
    border-radius: 6px;
    p {
      font-size: 14px;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
// Dropzone

.icon {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0;
  &.icon-left {
    margin-right: 8px;
  }
  &.icon-right {
    margin-left: 8px;
  }
  > svg {
    width: 100%;
    height: 100%;
  }
  &.icon-xxs {
    width: 13px;
    height: 13px;
  }
  &.icon-xs {
    width: 16px;
    height: 16px;
  }
  &.icon-sm {
    width: 20px;
    height: 20px;
  }
}

.card {
  border: 0 solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 4px 6px rgba(0,0,0,.04);
  .card-header {
    border-bottom: 0;
    background-color: #fff;
  }
}
.card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.167;
  color: rgb(61, 61, 61);
  margin-bottom: 0;
}
.card-subtitle {
  font-size: 13px;
  color: rgb(101, 101, 101);
  margin-top: 2px;
}
.card-body {
  padding: 15px;
  .card-header {
    padding: 0;
  }
}

.card-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  padding: 4px;
  border-radius: 6px;
}

.hr-divider {
  display: block;
  margin: 12px 0;
  border-top: 1px solid #e6e6e6;
}

.text-color-blue {
  color: #4462ef;
}
.text-color-pink {
  color: #ef4e77;
}
.text-color-green {
  color: #149f7f;
}
.text-color-yellow {
  color: #d98c4c;
}
.text-color-perple {
  color: #706cd6;
}
.text-color-cyan {
  color: #25b3cc;
}

.color-blue {
  color: #4462ef;
  background-color: #ededfa;
}
.color-pink {
  color: #ef4e77;
  background-color: #fcdce4;
}
.color-green {
  color: #149f7f;
  background-color: #d1f4ec;
}
.color-yellow {
  color: #d98c4c;
  background-color: #fceddc;
}
.color-purple {
  color: #706cd6;
  background-color: #dbdaf5;
}
.color-cyan {
  color: #25b3cc;
  background-color: #c0ecf3;
}

.timestamp {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  color: rgb(132, 132, 132);
  &.timestamp-background {
    color: rgb(101, 101, 101);
    background-color: rgb(240, 242, 250);
    padding: 2px 4px;
    border-radius: 5px;
    display: inline;
  }
}

.tabs {
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid rgb(224, 224, 224);
  padding: 0 15px;
  .btn-tab {
    position: relative;
    padding: 8px 10px;
    border: 0;
    &.active {
      color: #4c47cc;
      background-color: rgb(237, 237, 250);
      &::after {
        content: "";
        display: block;
        height: 2px;
        background-color: #4c47cc;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -2px;
      }
      .tab-budge {
        color: #4c47cc;
        background-color: #ededfa;
      }
    }
  }
  .tab-budge {
    display: inline-block;
    margin-left: 12px;
    font-weight: 500;
    font-size: 13px;
    color: currentColor;
    color: rgb(132, 132, 132);
    background-color: rgb(240, 240, 240);
    padding: 2px 4px;
    border-radius: 3px;
  }
}

.avatar {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  list-style: none;
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .avatar-string {
    position: absolute;
    left: 50%;
    transform: scale(1) translateX(-50%);
    transform-origin: 0 center;
  }
}
 
// Checkbox Css::Start
.cm-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  .cm-checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
}
.cm-checkbox {
  .cm-checkbox-input:checked ~ .checkmark {
    background-color: #3866e6;
    border: 1px solid #3866e6;
  }
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.cm-checkbox {
  .cm-checkbox-input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.cm-checkbox-line {
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  .checkmark {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 0;
    &:after {
      left: 7px;
      top: 2px;
    }
  }
}
// Checkbox
 

// Dropzone
.dropzone-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 15px;
}
.dropzone {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  &.dropzone-md {
    width: 100%;
    margin: 0 auto;
    .dropzone-inner-area  {
      height: 176px;
    }
  }
  &.dropzone-sm {
    width: 100%;
    margin: 0 auto;
    .dropzone-inner-area  {
      height: 200px;
    }
  }
  .upload-container {
    cursor: pointer;
  }
  .dropzone-inner-area {
    width: 100%;
    height: 176px;
    background: #fff;
    border: 2px dashed #B4B4B4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:focus {
      outline: none;
    }
  }
  .upload-text {
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 5px;
  }
  .btn-upload {
    min-width: 177px;
    padding: .375rem 1.5rem;
  }
}
.dropzone-note {
  font-size: 13px;
  line-height: 17px;
  color: #000000;
}
// Dropzone



// Tabs
.tabs__links--wrapper {
  padding: 10px 0;
}
.tabs__links {
  display: flex;
  align-items: center;
  position: relative;
  // background: #fff;
  border-bottom: 1px solid #e5e5e5;
  width: calc(100% - 60px);
  // max-width: 1320px;
  margin: 0 auto;
  &.tabs__links--space {
    margin-bottom: 20px;
  }
  &.tabs__links--full {
    width: 100%;
  }
  .tabs__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #697386;
    background: transparent;
    background-image: none;
    text-decoration: none;
    position: relative;
    padding: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-bottom: 2px solid transparent;
    margin-right: 26px;
    &:hover,
    &:focus {
      outline: none;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: #C800FF;
      border-bottom: 2px solid #C800FF;
    }
  }
}

.tabs__underline {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 22px;
  .btn__tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #697386;
    background: transparent;
    background-image: none;
    text-decoration: none;
    position: relative;
    padding: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid transparent;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: #000;
      border-bottom: 2px solid #000;
    }
  }
}

// Tabs

// Head

.head {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 66px;
  background: #fff;
  border-bottom: 1px solid #d9dce1;
  &.head__fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .head__container {
    width: calc(100% - 30px);
    // max-width: 1320px;
    margin: 0 auto;
    &.head__container--full {
      max-width: 100%;
    }
  }
  .head__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }
  .head__left {
    display: flex;
    align-items: center;
  }
  .head__close {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .btn__close {
      &:hover {
        background: #f2f2f2;
      }
    }
  }
  .head__divider {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    width: 1px;
    height: 24px;
    background: #d9dce1;
    @media (min-width: 1024px) {
      margin: 0 20px;
    }
  }
  .head__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1a1f36;
  }
  .head__right {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    .btn__head {
      white-space: nowrap;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
// Head


// Page header
.page__header {
  width: 100%;
  padding: 10px 0 20px;
  
  .page__header--container {
    width: calc(100% - 60px);
    // max-width: 1320px;
    margin: 0 auto;
  }
  .page__header--row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 35px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .page__header--left {
    .page__header--title {
    
      font-weight: bold;
      font-size: 22px;
      line-height: normal;
      color: #1a1f36;
      margin-bottom: 10px;
    }
    .page__header--subtitle {
      font-size: 16px;
      line-height: normal;
      color: #1a1f36;
      margin: 0;
   
      a {
        color: #C800FF;
        text-decoration: none;
      }
    }
  }
  .page__header--right {
    margin-top: 20px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
    .btn {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      + .btn {
        margin-top: 20px;
        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
    }
  }
}
// Page header


// Table
.table__responsive--custom {
}
table.table__custom {
  margin-bottom: 0;

  > :not(:first-child) {
    border-top: 0;
  }
  thead {
    border-bottom: 1px solid #e3e8ee;
    th {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #1a1f36;
      padding: 0.75rem 0.5rem;
      white-space: nowrap;
    }
  }
  tbody {
    tr {
      &:hover {
        background: #fafafa;
      }
      td {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1a1f36;
        padding: 0.625rem 0.5rem;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
  .table__selection--column {
    width: 20px;
  }
  .table__cell--first {
    width: 20%;
  }

  .table__cell--last {
    text-align: right;
  }
}
.table__blank {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .table__blank--text {
    color: #697386;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}
// Table

// Table Pagination
.table__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;
  .table__pagination--results {
  }
  .table__pagination--nav {
    .table__pagination--prev,
    .table__pagination--next {
      margin-left: 8px;
    }
  }
}
// Table Pagination

.container-wrapper {
  padding: 0 30px;
}