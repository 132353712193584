.auth {
  width: 100%;
  position: relative;
  // background: #f7f7f8;
}
.auth-main {
  display: flex;
  align-items: center;
  min-height: 100vh;
  overflow: auto;
}
.auth-col {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 20px;
}

.auth-card {
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 8px;
  border-radius: 1rem;
  padding: 24px;
}

.auth-header {
  margin-bottom: 20px;
}
.auth-logo {
  margin-bottom: 20px;
}
.auth-header-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25em;
  margin-bottom: 0.25rem;
}
.auth-header-subtitle {
  font-size: 0.9375rem;
  line-height: 1.25rem;
  letter-spacing: 0px;
  font-weight: 500;
  margin-bottom: 0;
}

.auth-divider {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  &::before ,
  &::after {
    content: '';
    display: block;
    width: 50%;
    background-color: #e6e6e6;
    height: 1px;
  }
  &::before  {
    margin-right: 15px;
  }
  &::after {
    margin-left: 15px;
  }
}

.auth-form {
}
.auth-otp-input-component {
  input {
    width: 50px !important;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #e4e9f4;
    font-size: 20px;
    font-weight: 600;
    appearance: none;
    &:focus {
      border-color: #4c47cc;
      // outline: none;
      outline-color: #4c47cc;
    }
  }
  span {
    margin: 0 5px;
  }
}
.auth-code-resend {
  font-size: 0.8125rem;
  text-align: left;
  color: rgb(101, 101, 101);
  margin: 0px 0 15px;
  .btn {
    padding: 0;
    line-height: inherit;
    font-size: inherit;
  }
}

.auth-agree-conditions {
  margin: 16px 0px;
  font-size: 13px;
  line-height: 1.33;
  color: rgb(101, 101, 101);
  font-weight: 400;
  .link {
    color: #4c47cc;
    &:hover {
      text-decoration: underline;
    }
  }
}

.auth-footer {
  font-size: 14px;
  text-align: left;
  color: rgb(101, 101, 101);
  margin: 0px;
  .auth-redirect-link {
    font-weight: 400;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
