@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-LightItalic.eot');
    src: url('PierSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('PierSans-LightItalic.woff') format('woff'),
        url('PierSans-LightItalic.ttf') format('truetype'),
        url('PierSans-LightItalic.svg#PierSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-Bold.eot');
    src: url('PierSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('PierSans-Bold.woff') format('woff'),
        url('PierSans-Bold.ttf') format('truetype'),
        url('PierSans-Bold.svg#PierSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-Black.eot');
    src: url('PierSans-Black.eot?#iefix') format('embedded-opentype'),
        url('PierSans-Black.woff') format('woff'),
        url('PierSans-Black.ttf') format('truetype'),
        url('PierSans-Black.svg#PierSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-BoldItalic.eot');
    src: url('PierSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('PierSans-BoldItalic.woff') format('woff'),
        url('PierSans-BoldItalic.ttf') format('truetype'),
        url('PierSans-BoldItalic.svg#PierSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-Medium.eot');
    src: url('PierSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('PierSans-Medium.woff') format('woff'),
        url('PierSans-Medium.ttf') format('truetype'),
        url('PierSans-Medium.svg#PierSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-Italic.eot');
    src: url('PierSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('PierSans-Italic.woff') format('woff'),
        url('PierSans-Italic.ttf') format('truetype'),
        url('PierSans-Italic.svg#PierSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-MediumItalic.eot');
    src: url('PierSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('PierSans-MediumItalic.woff') format('woff'),
        url('PierSans-MediumItalic.ttf') format('truetype'),
        url('PierSans-MediumItalic.svg#PierSans-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-BlackItalic.eot');
    src: url('PierSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('PierSans-BlackItalic.woff') format('woff'),
        url('PierSans-BlackItalic.ttf') format('truetype'),
        url('PierSans-BlackItalic.svg#PierSans-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-Regular.eot');
    src: url('PierSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('PierSans-Regular.woff') format('woff'),
        url('PierSans-Regular.ttf') format('truetype'),
        url('PierSans-Regular.svg#PierSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Pier Sans';
    src: url('PierSans-Light.eot');
    src: url('PierSans-Light.eot?#iefix') format('embedded-opentype'),
        url('PierSans-Light.woff') format('woff'),
        url('PierSans-Light.ttf') format('truetype'),
        url('PierSans-Light.svg#PierSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

