.e-aside {
  background: #fff;
  border-right: 1px solid #e6e6e6;
  overflow: auto;
  scrollbar-width: thin;
}
.e-aside-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.e-aside-header {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  // overflow: hidden;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
  margin-bottom: 16px;
  &.e-aside-subheader {
    min-height: 44px;
    padding: 0 16px;
    border-bottom: 1px solid #000;
    .btn-e-aside-nav {
      &:hover {
        background-color: #e6e6e6;
      }
    }
  }
}
.e-aside-header-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .btn.btn-e-aside-nav {
    border: 0;
    border-bottom: 2px solid transparent;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    min-height: 44px;
    &:hover {
      background-color: #FAFAFA;
    }
    &.active {
      background-color: #FAFAFA;
      border-bottom: 2px solid #000;
    }
  }
}
.e-aside-header-title {
  font-weight: 700;
  font-size: 16px;
  color: #000;
}


.e-aside-body {
  padding: 0 0 16px;
}
.e-aside-body-content {
  padding: 0 16px;
}

 

.e-aside-head {
  padding: 0 16px;
  margin-bottom: 8px;
}
.e-aside-head-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
}
.e-aside-head-subtitle {
  font-size: 12px;
  margin-bottom: 0;
}


.e-aside-links {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.btn.e-aside-link {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0;
  border: 0;
  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }
  &.active,
  &:active,
  &:hover {
    color: #fff;
    background-color: #111;
    box-shadow: none;
  }
}




.e-preview {
  background: #FFFFFF;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  overflow: hidden;
  .e-preview-thumb {
    height: 0;
    position: relative;
    padding-top: 66.66%;
    &.e-preview-thumb-logo,
    &.e-preview-thumb-favicon {
      img {
        object-fit: contain;
        padding: 10px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .e-preview-cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e6e6e6;
    .btn,
    .dropzone-inner {
      font-size: 14px;
      border-radius: 0;
      width: 100%;
      border-right: 1px solid #D8D8D8;
      &:last-child {
        border-right: 0 solid #D8D8D8;
      }
    }
    .btn-remove {
      color: #DF0000;
    }
  }
}
.e-preview-crop-cta {
  padding: 10px 15px;
  text-align: center;
}
.e-preview-adjustment {
  padding: 15px;
  border-top: 1px solid #D8D8D8;
  .e-preview-adjustment-wrap {

  }
  .e-preview-adjustment-range {
    display: flex;
    align-items: center;
    .adjustment-range-thumb {
      width: 100%;
    }
  }
  .adjust-logo {
    margin-left: .8rem;
  }
}

.e-aside-footer {
  padding: 16px;
  background: #fff;
  position: sticky;
  bottom: 0;
  z-index: 10;
  border-top: 1px solid #e6e6e6;
  margin-top: auto;
}


// Accordion
.accordion-block-section {
  margin-bottom: 15px;
  .accordion-item {
    .accordion-header {
      .accordion-button {
        font-weight: 500;
        padding-left: 30px;
        &::after {
          position: absolute;
          left: 5px;
          transform: rotate(0);
          background-size: 16px;
          background-position: center;
        }
        &.collapsed {
          &::after {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
} 
// Accordion 