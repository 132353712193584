@font-face {
    font-family: 'Cerebri Sans';
    src: url('CerebriSans-Light.woff2') format('woff2'),
        url('CerebriSans-Light.woff') format('woff'),
        url('CerebriSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('CerebriSans-Medium.woff2') format('woff2'),
        url('CerebriSans-Medium.woff') format('woff'),
        url('CerebriSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('CerebriSans-Heavy.woff2') format('woff2'),
        url('CerebriSans-Heavy.woff') format('woff'),
        url('CerebriSans-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans Book';
    src: url('CerebriSans-Book.woff2') format('woff2'),
        url('CerebriSans-Book.woff') format('woff'),
        url('CerebriSans-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('CerebriSans-ExtraBold.woff2') format('woff2'),
        url('CerebriSans-ExtraBold.woff') format('woff'),
        url('CerebriSans-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('CerebriSans-Bold.woff2') format('woff2'),
        url('CerebriSans-Bold.woff') format('woff'),
        url('CerebriSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('CerebriSans-SemiBold.woff2') format('woff2'),
        url('CerebriSans-SemiBold.woff') format('woff'),
        url('CerebriSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('CerebriSans-Regular.woff2') format('woff2'),
        url('CerebriSans-Regular.woff') format('woff'),
        url('CerebriSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

